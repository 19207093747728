import React, { useEffect, useState } from 'react'
import { Form, FormGroup, Label, FormText, CustomInput, Input, Button, FormFeedback } from "reactstrap";
import { Inputs, SelectOptionsWithDanger } from '../../../../../library/components/Components';
import DatePicker from "react-datepicker";
import Typography from '@mui/material/Typography';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { addYears } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import CustomAccordion from '../../../../atom/CustomAccordion';


export default function ContentModalPersonal(props) {
    const [fieldState, setFieldState] = useState({
        field: {
            bidang_usaha: '',
            penghasilan: '',
            sumber_penghasilan: '',
            pekerjaan: '',
            perkawinan: ''

        }, error: {
            bidang_usaha: '',
            penghasilan: '',
            sumber_penghasilan: '',
            pekerjaan: '',
            perkawinan: ''
        }
    })
    // const [accordionState, setAccordionState] = useState({
    //     accordion1: false,
    //     accordion2: false,
    //     accordion3: false,
    //     accordion4: false,
    // })

    // const toggleAccordion = () => {
    //     setAccordionState({ accordion1: accordionState.accordion1 })
    // }
    const onChangeHandler = (type) => (e) => {
        e.persist(); // Ensure event properties are retained
        // console.log(e.target.value); // Check the value here
        setFieldState(prevState => ({
            ...prevState,
            field: {
                ...prevState.field,
                [type]: e.target.value
            },
            error: {
                ...prevState.error,
                [type]: ""
            }
        }));
    };

    const onSubmitHandler = () => {
        let error = {}
        for (let i in fieldState.field) {
            if (fieldState.field[i] == "") {
                error[i] = "Field is required"
            }
        }
        setFieldState(prevState => ({
            ...prevState,
            error: {
                ...prevState.error,
                ...error
            }
        }));
        if (error.penghasilan || error.pekerjaan || error.bidang_usaha ||

            error.sumber_penghasilan ||

            error.perkawinan) {
            return;
        }
        else {
            props.onSubmit(fieldState.field)
        }
    }
    useEffect(() => {
        // console.log(props.master)
        // setFieldState({field:})
    }, [])
    const onChangeBidangUsahaHandler = (index) => (e) => {
        // console.log(e.target.value)
        if (fieldState.field.bidang_usaha.length == index) {
            const newArr = [...fieldState.field.bidang_usaha, e.target.value]
            setFieldState(item => ({ ...item, field: { bidang_usaha: newArr } }))
        }

    }
    const renderSubAccordions = (subItems, level = 2) => {
        if (subItems?.kode !== "") {
            return (
                <div style={{ fontSize: "14px", marginLeft: `${level * 15}px` }}>

                    <FormControlLabel sx={{ fontSize: '14px' }} value={subItems.kode} control={<Radio size='sm' sx={{
                        '&, &.Mui-checked': {
                            color: 'orange',
                        },
                    }} />} label={<Typography sx={{ fontSize: '14px' }}>{subItems.keterangan}</Typography>} />

                </div>
            )
        }
        if (!subItems?.sub) {
            return (
                <>
                    {subItems?.sub?.map(item => (

                        <FormControlLabel sx={{ fontSize: "14px", marginLeft: `${level * 15}px`, width: '100%' }} key={item.kode} value={item.kode} control={<Radio size='sm' sx={{
                            '&, &.Mui-checked': {
                                color: 'orange',
                            },
                        }} />} label={<Typography sx={{ fontSize: '14px' }}>{item.keterangan}</Typography>} />

                    ))}
                </>
            )
        }
        return subItems.sub.map((subItem, index) => (
            <CustomAccordion key={subItem.kode} data={subItem} space={level * 10} title={subItem.keterangan} detail={subItem.detail} withBorder={false}>
                {renderSubAccordions(subItem, level + 1)} {/* Recursive call for nested sub-items */}
            </CustomAccordion>
        ));
    }
    return (
        <>
            <div style={{ overflowY: 'auto', maxHeight: '60vh', overflowX: 'hidden' }}>
                <div className='row mb-4'>
                    <div className='col-12 col-md-6'>
                        <SelectOptionsWithDanger
                            value={fieldState.field.penghasilan}
                            forLabel={"penghasilan"}
                            label={"Penghasilan"}
                            onChange={onChangeHandler('penghasilan')}
                            name={"penghasilan"}
                            error={fieldState.error.penghasilan}
                            default={"Penghasilan"}
                            data={props.master.masterListPusdafil.pendapatan}
                            readOnly={false}
                        />

                    </div>
                    <div className='col-12 col-md-6'>
                        <SelectOptionsWithDanger
                            value={fieldState.field.sumber_penghasilan}
                            forLabel={"sumber_penghasilan"}
                            label={"Sumber Penghasilan"}
                            onChange={onChangeHandler('sumber_penghasilan')}
                            // onChange={onChangeBidangUsahaHandler(0)}
                            name={"sumber_penghasilan"}
                            error={fieldState.error.sumber_penghasilan}

                            default={"Sumber Penghasilan"}
                            data={props.master.masterListPusdafil?.sumber_penghasilan}
                            readOnly={false}
                        />

                    </div>

                </div>

                <div className='row mb-4'>

                    <div className='col-12 col-md-6'>
                        <SelectOptionsWithDanger
                            value={fieldState.field.pekerjaan}
                            forLabel={"pekerjaan"}
                            label={"Pekerjaan"}
                            onChange={onChangeHandler('pekerjaan')}
                            name={"pekerjaan"}
                            error={fieldState.error.pekerjaan}

                            default={"Pekerjaan"}
                            data={props.master.masterListPusdafil?.pekerjaan}
                            readOnly={false}
                        />


                    </div>
                    <div className='col-12 col-md-6'>

                        <SelectOptionsWithDanger
                            value={fieldState.field.perkawinan}
                            forLabel={"status perkawinan"}
                            label={"Status Perkawinan"}
                            onChange={onChangeHandler('perkawinan')}
                            name={"perkawinan"}
                            error={fieldState.error.perkawinan}

                            default={"Status Perkawinan"}
                            data={props.master.masterListPusdafil?.status_perkawinan}
                            readOnly={false}
                        />

                    </div>

                </div>

                <div className='row mb-4'>

                </div>
                <hr />

                {/* <div className='row mb-4'>
                {fieldState.field.bidang_usaha.length >= 0 &&
                    <div className='col-12'>
                        <SelectOptionsWithDanger
                            value={props.data_sk ? props.data_sk : props.status_perkawinan}
                            forLabel={"bidang_usaha"}
                            label={"Bidang Usaha"}
                            onChange={onChangeBidangUsahaHandler(0)}

                            name={"bidang_usaha"}
                            default={"Bidang Usaha"}
                            data={props.master.masterListPusdafil?.sektor_ekonomi}
                            readOnly={false}
                        />
                    </div>
                }
                {fieldState.field.bidang_usaha.length >= 1 &&
                    <div className='col-12'>
                        <SelectOptionsWithDanger
                            value={props.data_sk ? props.data_sk : props.status_perkawinan}
                            forLabel={"bidang_usaha"}
                            label={""}
                            onChange={onChangeBidangUsahaHandler(1)}
                            name={"bidang_usaha"}
                            default={"Bidang Usaha"}
                            data={props.master.masterListPusdafil?.sektor_ekonomi[0].sub}
                            readOnly={false}
                        />
                    </div>
                }
                {fieldState.field.bidang_usaha.length >= 2 &&
                    <div className='col-12'>
                        <SelectOptionsWithDanger
                            value={props.data_sk ? props.data_sk : props.status_perkawinan}
                            forLabel={"bidang_usaha"}
                            label={""}
                            onChange={onChangeBidangUsahaHandler(2)}
                            name={"bidang_usaha"}
                            default={"Bidang Usaha"}
                            data={props.master.masterListPusdafil?.sektor_ekonomi[0].sub[0].sub}
                            readOnly={false}
                        />
                    </div>
                }
                {fieldState.field.bidang_usaha.length >= 3 &&
                    <div className='col-12'>
                        <SelectOptionsWithDanger
                            value={props.data_sk ? props.data_sk : props.status_perkawinan}
                            forLabel={"bidang_usaha"}
                            label={""}
                            onChange={onChangeBidangUsahaHandler(3)}
                            name={"bidang_usaha"}
                            default={"Bidang Usaha"}
                            data={props.master.masterListPusdafil?.sektor_ekonomi[0].sub[0].sub[0].sub}
                            readOnly={false}
                        />
                    </div>
                }
                {fieldState.field.bidang_usaha.length >= 4 &&
                    <div className='col-12'>
                        <SelectOptionsWithDanger
                            value={fieldState.field.bidang_usaha[4]}

                            forLabel={"bidang_usaha"}
                            label={""}
                            onChange={onChangeBidangUsahaHandler(4)}
                            name={"bidang_usaha"}
                            default={"Bidang Usaha"}
                            data={props.master.masterListPusdafil?.sektor_ekonomi[0].sub[0].sub[0].sub}
                            readOnly={false}
                        />
                    </div>
                }
                {fieldState.field.bidang_usaha.length > 5 &&

                    <div className='col-12'>
                        <SelectOptionsWithDanger
                            value={fieldState.field.bidang_usaha[5]}
                            forLabel={"bidang_usaha"}
                            label={""}
                            onChange={onChangeBidangUsahaHandler(5)}
                            name={"bidang_usaha"}
                            default={"Bidang Usaha"}
                            data={props.master.masterListPusdafil?.sektor_ekonomi[0].sub[0].sub[0].sub[0].sub}
                            readOnly={false}
                        />

                    </div>
                }

            </div> */}
                <div className='mb-3'>
                    <FormGroup className='p-1'>
                        <FormControl>

                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue=""

                                value={fieldState.field.bidang_usaha} // Bind value to state
                                name="radio-buttons-group"
                                onChange={onChangeHandler('bidang_usaha')}
                            >
                                <CustomAccordion title={'Pilih Bidang Usaha'} withBorder detail={''}>
                                    {props.master.masterListPusdafil?.sektor_ekonomi?.map(item => (
                                        <CustomAccordion data={item} title={item.keterangan} detail={item.detail} key={item.kode} space={10} withBorder={false}>
                                            {renderSubAccordions(item)}
                                        </CustomAccordion >

                                    ))
                                    }
                                </CustomAccordion >
                            </RadioGroup>
                        </FormControl>
                        <FormText color="danger" style={{ fontSize: "11px" }}>
                            {fieldState.error?.bidang_usaha === "" ? null : fieldState.error?.bidang_usaha}
                        </FormText>
                    </FormGroup>
                </div>
            </div>
            <div className='col-12 row flex-1 justify-content-end p-0'>
                <div className='row d-flex gap-2'>
                    <div className="d-flex align-items-center flex-1 col-12 col-md-6  justify-content-end pr-0">
                        <Button outline className="btn btn-md btn-block card-submit" onClick={() => { props.toggle() }}>
                            Cancel
                        </Button>

                    </div>
                    <div className="d-flex align-items-center flex-1 col-12 col-md-6 justify-content-end p-0">
                        <Button
                            color="primary"
                            disabled={props.loading}
                            className="btn btn-primary btn-block btn-md d-flex justify-content-center align-items-center gap-1"
                            onClick={onSubmitHandler}>
                            {props.loading &&
                                <span className="loader"></span>
                            }
                            Simpan
                        </Button>

                    </div>

                </div>
            </div>
        </>

    )
}
