import React, { Component } from "react";
import { Button } from "reactstrap";
import ReactQuill from "react-quill";
import { connect } from "react-redux";
import { getDetailTiketing, patchEndTiketing, postNewMessageTiketing } from "../../redux/action/Tiketing";
import { getFullTime } from "../../library/helper/helper";
import { encryptedData } from "../../library/helper/Crypto";
import Swal from "sweetalert2";
import Loader from "react-loader-spinner";
import "./styles.scss";
import "./tiketing.scss";

class TiketingDetailsPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tiketing: {
                message: []
            },
            deskripsi: "",
            attachment_satu: null,
            attachment_dua: null,
            attachment_tiga: null,
            attachment_empat: null
        };
    }

    submitTiketing = async () => {
        const { deskripsi, attachment_satu, attachment_dua, attachment_tiga, attachment_empat } = this.state;

        const formData = new FormData();
        if (attachment_satu) formData.append("attachment_satu", attachment_satu);
        if (attachment_dua) formData.append("attachment_dua", attachment_dua);
        if (attachment_tiga) formData.append("attachment_tiga", attachment_tiga);
        if (attachment_empat) formData.append("attachment_empat", attachment_empat);
        formData.append("deskripsi", encryptedData(deskripsi));

        await this.props
            .dispatch(postNewMessageTiketing(this.props.match.params.id, formData))
            .then((res) => {
                Swal.fire({
                    title: "Berhasil Mengirim Pengaduan! ",
                    text: "Silahkan periksa Email anda untuk melihat Tracking Ticket ID & memeriksa Balasan.",
                    icon: "success",
                    button: "ok"
                }).then(() => {
                    window.location.reload();
                });
            })
            .catch((err) => {
                Swal.fire({
                    title: "Gagal, Terjadi Kesalahan",
                    text: `${err.response.data.message}`,
                    icon: "warning",
                    button: "ok"
                });
            });
    };

    endStatusTiketing = async () => {
        Swal.fire({
            title: "Akhiri Pengaduan",
            text: "Yakin Mengakhiri Pengaduan?",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yakin"
        })
            .then(async (res) => {
                if (res.value) {
                    await this.props.dispatch(patchEndTiketing(this.props.match.params.id)).then(() => {
                        Swal.fire({
                            title: "Berhasil Mengakhiri Pengaduan! ",
                            text: "Silahkan periksa Email anda untuk melihat Tracking Ticket ID & memeriksa Balasan.",
                            icon: "success",
                            button: "ok"
                        }).then(() => {
                            window.location.reload();
                        });
                    });
                }
            })
            .catch((err) => {
                Swal.fire({
                    title: "Gagal, Terjadi Kesalahan",
                    text: `${err.response.data.message}`,
                    icon: "warning",
                    button: "ok"
                });
            });
    };

    async componentDidMount() {
        await this.props.dispatch(getDetailTiketing(this.props.match.params.id));
        this.setState({
            tiketing: this.props.tiketing.tiketingDetail
        });
    }

    handleUploadFile = (e) => {
        let file = e.target.files[0];
        this.setState({
            [e.target.name]: file
        });
    };

    handleRemoveFile = (state) => {
        this.setState({
            [state]: null
        });
    };

    handleDeskripsi = (e) => {
        this.setState({
            deskripsi: e
        });
    };

    render() {
        const { tiketing, deskripsi, attachment_satu, attachment_dua, attachment_tiga, attachment_empat } = this.state;

        return (
            <section id="detail-tiketing" className="with-pad bg-white">
                {this.props.tiketing.isLoadingDetail || this.props.tiketing.isLoadingUpdateStatus ? (
                    <Loader type="ThreeDots" color="#FF7A00" height={100} width={100} className="loading-dots" />
                ) : (
                    <div className="container no-lg">
                        <h1 className="my-5">Ticketing Support Indofund</h1>
                        {tiketing.status_tiket === "Selesai" && <div className="done-box-status">Tiket Pengaduanmu Telah Selesai</div>}
                        <div className="card-primary">
                            <div className="d-flex justify-content-between align-items-center">
                                <div>
                                    <ListTitle title={"ID Tiket"} value={tiketing.id_tiket} />
                                    <ListTitle title={"Status Tiket"} value={tiketing.status_tiket} status={tiketing.status_tiket} />
                                    {/* <ListTitle title={"Tipe Akun"} value={tiketing.kode_pengguna === 1 ? "Lender" : "Borrower"} /> */}
                                    <ListTitle title={"Waktu di Buat"} value={getFullTime(tiketing.createdAt)} />
                                    <ListTitle title={"Terakhir di Update"} value={getFullTime(tiketing.last_update)} />
                                </div>

                                {tiketing.status_tiket !== "Selesai" && (
                                    <div>
                                        <Button className="button-end" onClick={this.endStatusTiketing}>
                                            Akhiri Pengaduan
                                        </Button>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="card-primary">
                            {tiketing.message.map((el, i) => {
                                return <ItemChat key={i} data={el} />;
                            })}
                        </div>
                        {tiketing.status_tiket !== "Selesai" && (
                            <div className="chat-box-container mt-4">
                                <p className="text-chat">Tambah Balasan</p>
                                <div className="box-chat-des">
                                    <ReactQuillEditor onChange={this.handleDeskripsi} value={deskripsi} />
                                </div>
                                <div className="d-flex justify-content-between mt-3">
                                    <div>
                                        <ButtonAttachment
                                            onChange={this.handleUploadFile}
                                            name={"attachment_satu"}
                                            value={attachment_satu}
                                            handleRemoveFile={this.handleRemoveFile}
                                            hiddenClose={attachment_dua !== null}
                                        />

                                        {attachment_satu && (
                                            <ButtonAttachment
                                                onChange={this.handleUploadFile}
                                                name={"attachment_dua"}
                                                value={attachment_dua}
                                                handleRemoveFile={this.handleRemoveFile}
                                                hiddenClose={attachment_tiga !== null}
                                            />
                                        )}
                                        {attachment_dua && (
                                            <ButtonAttachment
                                                onChange={this.handleUploadFile}
                                                name={"attachment_tiga"}
                                                value={attachment_tiga}
                                                handleRemoveFile={this.handleRemoveFile}
                                                hiddenClose={attachment_empat !== null}
                                            />
                                        )}
                                        {attachment_tiga && (
                                            <ButtonAttachment
                                                onChange={this.handleUploadFile}
                                                name={"attachment_empat"}
                                                value={attachment_empat}
                                                handleRemoveFile={this.handleRemoveFile}
                                            />
                                        )}
                                    </div>

                                    <Button
                                        color="primary"
                                        className="btn px-4"
                                        style={{ height: "45px" }}
                                        onClick={this.submitTiketing}
                                        disabled={this.props.tiketing.isLoadingMessage}>
                                        {this.props.tiketing.isLoadingMessage ? <span className="loader"></span> : "Submit"}
                                    </Button>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </section>
        );
    }
}

const ItemChat = ({ data }) => {
    return (
        <div>
            {data.author.nama_pengguna ? (
                <div className="d-flex my-4">
                    <div className="box-chat-user">
                        <p className="name-text mb-3">
                            {data.author.nama_pengguna} <span className="date-text">{getFullTime(data.createdAt)}</span>
                        </p>
                        <div dangerouslySetInnerHTML={{ __html: data.message }} />
                        {data.attachment_images.length > 0 && (
                            <div className="d-flex gap-3">
                                {data.attachment_images.map((link, index) => {
                                    return (
                                        <div key={index} className="d-flex mb-2 mr-3">
                                            <img src={link} alt="sdsd" style={{ width: "120px" }} />
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                    </div>
                    <div className="avatar" style={{ background: "#EDEDED" }}>
                        I
                    </div>
                </div>
            ) : (
                <div className="d-flex">
                    <div className="avatar" style={{ background: "#FFFAF4" }}>
                        CS
                    </div>
                    <div className="box-chat-admin">
                        <p className="name-text mb-2">Costumer Service Indofund</p>
                        <p className="date-text mb-2">{getFullTime(data.createdAt)}</p>
                        <div dangerouslySetInnerHTML={{ __html: data.message }}></div>
                        {data.attachment_images.length > 0 && (
                            <div className="d-flex gap-3">
                                {data.attachment_images.map((link, index) => {
                                    return (
                                        <div key={index} className="d-flex mb-2 mr-3">
                                            <img src={link} alt="sdsd" style={{ width: "120px" }} />
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

const ListTitle = ({ title, value, status = false }) => {
    return (
        <div className="d-flex">
            <p className="title-list" style={{ width: "144px" }}>
                {title}
            </p>
            <p
                className={`title-list ${status ? "active-status" : ""}`}
                style={{
                    color:
                        status === "Menunggu Balasan"
                            ? "#784421"
                            : status === "Baru"
                            ? "#1979F5"
                            : status === "Diproses"
                            ? "#FF7A00"
                            : status === "Selesai"
                            ? "#3D7D40"
                            : ""
                }}>
                : {value}
            </p>
        </div>
    );
};

const ButtonAttachment = ({ onChange, name, value, handleRemoveFile, hiddenClose = false }) => {
    return (
        <div className="d-flex align-items-center">
            <div className="button-attachment mr-3">
                <input
                    type="file"
                    name={name}
                    id="exampleFile"
                    style={{ opacity: 0, width: "50px", position: "absolute", cursor: "pointer" }}
                    onChange={(e) => onChange(e)}
                />
                <img className="img-fluid img-email" src={process.env.PUBLIC_URL + "../../images/icon/attachment.svg"} alt="logo-send" />
            </div>

            {value && (
                <div className="d-flex">
                    <p className="text-chat m-0">{value && value.name}</p>{" "}
                    {!hiddenClose && (
                        <i
                            onClick={() => handleRemoveFile(name)}
                            className="material-icons-outlined m-0"
                            style={{ color: "#757575", cursor: "pointer" }}>
                            cancel
                        </i>
                    )}
                </div>
            )}
        </div>
    );
};

const ReactQuillEditor = ({ onChange, value }) => {
    return (
        <div>
            <ReactQuill
                value={value}
                placeholder="Ketik balasan..."
                style={{ background: "#fff", borderRadius: "8px" }}
                theme="snow"
                onChange={(e) => onChange(e)}
            />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        tiketing: state.tiketing
    };
};
export default connect(mapStateToProps)(TiketingDetailsPage);
