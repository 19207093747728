import React from "react";

export const CustomInputDate = ({ value, onClick }) => {
  return (
    <div className="d-flex justify-content-between position-relative" onClick={onClick}>
      <input
        type="text"
        className="form-control"
        value={value} // Display the selected date
      />
      <i className="d-flex justify-content-end material-icons position-absolute " style={{top:10,right:14,fontSize:'16px',color:'#868686'}}>calendar_today</i>
    </div>
  );
};
