import React from "react";
import Skeleton from "react-loading-skeleton";

function LoadingDataStatistik() {
    return (
        <div>
            <section id="statistik">
                <div className="sec-header">
                    <h4 className="text-center sec-title">Statistik</h4>
                </div>
                <div className="row text-center">
                    <div className="col-12 col-md-4 mb-3">
                        <div className="card border-0 shadow-sm h-100">
                            <div className="card-body">
                                <div className="col d-flex flex-column justify-content-between h-100" style={{ gap: '2.25rem' }}>
                                    <div className="col-12 d-flex justify-content-center align-items-center" style={{ flex: 'auto', maxHeight: '80px' }}>
                                        <div className="icon-wrapper d-flex flex-1 justify-content-center icon-responsive"  >
                                            <Skeleton width={80} height={80} />
                                        </div>
                                    </div>
                                    <div className="col-12 d-flex flex-column justify-content-between flex-1" >
                                        <h6 >Total Pendanaan</h6>

                                        <h3 className="text-title p-0 mt-2" style={{ fontSize: '1.5rem' }}>
                                            <Skeleton height={30} />
                                        </h3>
                                        <h6 className="p-0 mt-2 f-14" style={{}}>Total Akumulasi Sejak Indofund Berdiri</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-4 mb-3">
                        <div className="card border-0 shadow-sm h-100">
                            <div className="card-body">
                                <div className="col d-flex flex-column justify-content-between h-100" style={{ gap: '2.25rem' }}>
                                    <div className="col-12 d-flex justify-content-center align-items-center" style={{ flex: 'auto', maxHeight: '80px' }}>
                                        <div className="icon-wrapper d-flex flex-1 justify-content-center icon-responsive" >
                                            <Skeleton width={80} height={80} />

                                        </div>
                                    </div>
                                    <div className="col-12 d-flex flex-column justify-content-between flex-1">
                                        <h6 >Total Pendanaan Pertahun</h6>

                                        <h3 className="text-title p-0 mt-2" style={{ fontSize: '1.5rem' }}>
                                            <Skeleton height={30} />

                                        </h3>
                                        <h6 className="p-0 mt-2 f-14" style={{}}>Total Akumulasi Pendanaan Tahun Ini</h6>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-4 mb-3">
                        <div className="card border-0 shadow-sm h-100">
                            <div className="card-body">
                                <div className="col d-flex flex-column justify-content-between h-100" style={{ gap: '2.25rem' }}>
                                    <div className="col-12 d-flex justify-content-center align-items-center" style={{ flex: 'auto', maxHeight: '80px' }}>
                                        <div className="icon-wrapper d-flex flex-1 justify-content-center icon-responsive" >
                                            <Skeleton width={80} height={80} />

                                        </div>
                                    </div>
                                    <div className="col-12 d-flex flex-column justify-content-between flex-1" >
                                        <h6 >Outstanding Pendanaan</h6>

                                        <h3 className="text-title p-0 mt-2" style={{ fontSize: '1.5rem' }}>
                                            <Skeleton height={30} />

                                        </h3>
                                        <h6 className="p-0 mt-2 f-14" style={{}}>Total Pendanaan Berjalan</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row text-center">
                    <div className="col-12 col-md-6 mb-3">
                        <div className="card border-0 shadow-sm">
                            <div className="card-body">
                                <div className="row gap-2">
                                    <div className="col-12 col-md-6 flex-column flex-md-row d-flex gap-3 justify-content-center align-items-center">
                                        <div className="icon-wrapper d-flex flex-1 justify-content-center icon-responsive">
                                            <Skeleton width={80} height={80} />
                                        </div>
                                        <h6 className="flex-1 text-left m-0 d-none d-lg-inline">Pemberi <br />Pendanaan</h6>
                                    </div>
                                    <div className="col-12 col-md-5 mt-md-4 d-flex flex-column justify-content-between align-items-center align-items-md-left text-center text-md-left gap-2">
                                        <div className="d-flex flex-column gap-1 w-100">
                                            <h6>Dari Awal Berdiri</h6>
                                            <h3 className="text-title p-0 m-0" style={{ fontSize: '1.5rem' }}>
                                                <Skeleton height={30} />
                                            </h3>
                                        </div>
                                        <div style={{ height: '1px', backgroundColor: '#1212121A', width: '100%' }}></div>

                                        <div className="d-flex flex-column gap-1 w-100">
                                            <h6>Tahun Berjalan</h6>
                                            <h3 className="text-title p-0 m-0" style={{ fontSize: '1.5rem' }}>
                                                <Skeleton height={30} />
                                            </h3>
                                        </div>
                                        <div style={{ height: '1px', backgroundColor: '#1212121A', width: '100%' }}></div>
                                        <div className="d-flex flex-column gap-1 w-100">
                                            <h6>Posisi Akhir</h6>
                                            <h3 className="text-title p-0" style={{ fontSize: '1.5rem' }}>
                                                <Skeleton height={30} />

                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 mb-3">
                        <div className="card border-0 shadow-sm">
                            <div className="card-body">
                                <div className="row gap-2">
                                    <div className="col-12 col-md-6 flex-column flex-md-row d-flex gap-3 justify-content-center align-items-center">
                                        <div className="icon-wrapper d-flex flex-1 justify-content-center" style={{ width: '100%', maxWidth: '100px', height: 'auto' }} >
                                            <Skeleton width={80} height={80} />
                                        </div>
                                        <h6 className="flex-1 text-left m-0 d-none d-lg-inline">Penerima <br /> Pendanaan</h6>
                                    </div>
                                    <div className="col-12 col-md-5 mt-md-4 d-flex flex-column justify-content-between align-items-center align-items-md-left text-center text-md-left gap-2">
                                        <div className="d-flex flex-column gap-1 w-100">
                                            <h6>Dari Awal Berdiri</h6>
                                            <h3 className="text-title p-0 m-0" style={{ fontSize: '1.5rem' }}>
                                                <Skeleton height={30} />
                                            </h3>
                                        </div>
                                        <div style={{ height: '1px', backgroundColor: '#1212121A', width: '100%' }}></div>

                                        <div className="d-flex flex-column gap-1 w-100">
                                            <h6>Tahun Berjalan</h6>
                                            <h3 className="text-title p-0 m-0" style={{ fontSize: '1.5rem' }}>
                                                <Skeleton height={30} />
                                            </h3>
                                        </div>
                                        <div style={{ height: '1px', backgroundColor: '#1212121A', width: '100%' }}></div>
                                        <div className="d-flex flex-column gap-1 w-100">
                                            <h6>Posisi Akhir</h6>
                                            <h3 className="text-title p-0" style={{ fontSize: '1.5rem' }}>
                                                <Skeleton height={30} />

                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export { LoadingDataStatistik };
