import React, { Component } from "react";
import "./choice.scss";
import Funds from "../../../assets/svg/Funds";
import Hand from "../../../assets/svg/Hand";

class ChoiceIndofund extends Component {
    render() {
        return (
            <section id="kenapa-pilih" className="pb-5 mb-5">
                <div className="container no-lg">
                    <div className="sec-title">
                        <h4 className="text-center title">Kenapa Memilih Indofund?</h4>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col col-xl-9 col-md-10">
                            <div className="row text-center">
                                <div className="col-12 col-xl-4 col-md-4 ">
                                    <Funds />
                                    <h5 className="title">Keuntungan Menarik</h5>
                                    <div className="text-secondary ">
                                        Suku bunga hingga 20% per tahun. Lebih tinggi dibandingkan rata-rata pendanaan konvensional lainnya.
                                    </div>
                                </div>
                                <div className="col-12 col-xl-4 col-md-4 content-padding">
                                    <img src={process.env.PUBLIC_URL + "/images/dana-proteksi/protection.png"} alt="2" />
                                    <h5 className="title">Aman dan Terpercaya</h5>
                                    <div className="text-secondary">
                                        Indofund berizin dan diawasi oleh OJK. Pengajuan pendanaan juga diseleksi ketat oleh tim profesional.
                                    </div>
                                </div>
                                <div className="col-12 col-xl-4 col-md-4">
                                    <Hand />
                                    <h5 className="title">Pendanaan Terjangkau</h5>
                                    <div className="text-secondary">Nilai pendanaan yang sangat terjangkau. Bisa mulai dari Rp 50 ribu saja.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default ChoiceIndofund;
