import React, { Component } from "react";
import { connect } from "react-redux";
import { LendTotal } from "../../../redux/action/Lender";
import Skeleton from "react-loading-skeleton";
import { ReactComponent as TotalPendanaanIcon } from '../../../component/assets/svg/statistics/icon-statistics/total_pendanaan_icon.svg'
import { ReactComponent as PendanaanOutstandingIcon } from '../../../component/assets/svg/statistics/icon-statistics/pendanaan_outstanding_icon.svg'
import { ReactComponent as PendanaanSelesaiIcon } from '../../../component/assets/svg/statistics/icon-statistics/pendanaan_selesai_icon.svg'
import { ReactComponent as TotalProfitIcon } from '../../../component/assets/svg/statistics/icon-statistics/total_profit_icon.svg'
import { ReactComponent as PembayaranJatuhTempoIcon } from '../../../component/assets/svg/statistics/icon-statistics/pembayaran_jatuh_tempo_icon.svg'
import { ReactComponent as PortfolioLancarIcon } from '../../../component/assets/svg/statistics/icon-statistics/portfolio_lancar_icon.svg'
import { Popover, PopoverBody, PopoverHeader } from "reactstrap";
import { percentageConvert } from "../../../library/helper/helper";
class DasborTotal extends Component {
    constructor(props) {
        super(props);
        this.onHover=this.onHover.bind(this);
        this.state = {
            activeTab: 0,
            info: [],
            popoverTotalPendanaan: false,
            popoverTotalProfit: false,
            popoverOutstanding: false,
            popoverJatuhTempo: false,
            popoverSelesai: false,
            popoverPortfolioLancar: false,

            saldo: props.saldo
        };
    }
    componentDidMount = async () => {
        await this.props.dispatch(LendTotal());
    };

    data = (data, output) => {
        if (data) {
            return "Rp. " + output;
        } else {
            return "Rp. 0";
        }
    };

   

    togglePopOver(type) {
        this.setState({ [type]: !this.state[type] })
    }

    onHoverLeave = (type) => {
        this.setState({
            [type]: false
        });
    };
    onHover = (type) => {
        this.setState({
            [type]: true
        });
    };
    render() {
        // console.log(this.props.lender)
        const totalPendanaan = this.props.lender.lenderTotal? this.props.lender.lenderTotal.data.sumLending : null;
        const pendanaanOutstanding = this.props.lender.lenderTotal ? this.props.lender.lenderTotal.data.sumOutstanding: null;
        const pendanaanSelesai = this.props.lender.lenderTotal ? this.props.lender.lenderTotal.data.sumOutstandingDone : null;
        const totalProfit = this.props.lender.lenderTotal ? this.props.lender.lenderTotal.data.sumInterest : null;
        const pembayaranJatuhTempo = this.props.lender.lenderTotal ? this.props.lender.lenderTotal.data.sumOutstandingDueDate : null;
        const portfolioLancar = this.props.lender.lenderTotal ? this.props.lender.lenderTotal.data.precentageCurrentOutstanding : null;
        // const sukubunga = Math.round(lenderTotalData && lenderTotalData.sumInterest);
        return (
            <div className="d-flex flex-column wrapper-statistics card border-0 shadow card-statistic mbc-6 pb-4">
                <h4 className="font-weight-semi-bold mbc-7">Pendanaan Anda</h4>
                <div className="d-flex flex-column gap-3">
                    <div className="row ">
                        <div className="col-12 col-md-4 mb-3 mb-md-0">
                            <div className="card-statistic">
                                <div className="">
                                    <div className="d-flex">
                                        <TotalPendanaanIcon className="text-primary material-icons mr-2" style={{ marginBlock: '4px' }} />
                                        {/* <i className="text-primary material-icons mr-3">account_box</i> */}
                                        <div className="d-flex flex-column gap-1">
                                            <p className="text-secondary d-flex gap-1 flex-1 mb-0">Total Pendanaan {' '}
                                                <span className="d-flex justify-content-center align-items-center" id="Popovers" data-html="true" onMouseEnter={() => this.onHover('popoverTotalPendanaan')} onMouseLeave={() => this.onHoverLeave('popoverTotalPendanaan')}>
                                                    <i className="material-icons-outlined text-primary" style={{ fontSize: "16px" }}>
                                                        info
                                                    </i>
                                                </span>
                                                <Popover
                                                    // className="d-none d-sm-block"
                                                    placement="bottom"
                                                    isOpen={this.state.popoverTotalPendanaan}
                                                    target="Popovers"
                                                    toggle={this.togglePopOver.bind(this, 'popoverTotalPendanaan')}
                                                >

                                                    <PopoverHeader className="popover-header dark-style" ></PopoverHeader>
                                                    <PopoverBody className="dark-style fs-n2">Nominal keseluruhan pendanaan yang dilakukan user</PopoverBody>
                                                </Popover>

                                            </p>
                                            <h6 className="font-weight-semi-bold mb-0">
                                                {this.props.lender.isLoadingTotal ? (
                                                    <Skeleton />
                                                ) : (
                                                    this.data(totalPendanaan, totalPendanaan && totalPendanaan.toLocaleString("IN"))
                                                )}
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="card-statistic">
                                <div className="">
                                    <div className="d-flex">
                                        <PendanaanOutstandingIcon className="text-primary material-icons mr-2" style={{ marginBlock: '4px' }} />
                                        <div className="d-flex flex-column gap-1">
                                            <p className="text-secondary d-flex gap-1 flex-1 mb-0">Pendanaan Outstanding {' '}
                                                <span className="d-flex justify-content-center align-items-center" id="Popovers3" data-html="true" onMouseEnter={() => this.onHover('popoverOutstanding')} onMouseLeave={() => this.onHoverLeave('popoverOutstanding')}>

                                                    <i className="material-icons-outlined text-primary" style={{ fontSize: "16px" }}>
                                                        info
                                                    </i>
                                                </span>
                                                <Popover
                                                    // className="d-none d-sm-block"
                                                    placement="bottom"
                                                    isOpen={this.state.popoverOutstanding}
                                                    target="Popovers3"
                                                    toggle={this.togglePopOver.bind(this, 'popoverOutstanding')}
                                                >

                                                    <PopoverHeader className="popover-header dark-style" ></PopoverHeader>
                                                    <PopoverBody className="dark-style fs-n2">Total seluruh pendanaan yang belum dibayar
</PopoverBody>
                                                </Popover>

                                            </p>
                                            <h6 className="font-weight-semi-bold mb-0">
                                                {this.props.lender.isLoadingTotal ? (
                                                    <Skeleton />
                                                ) : (
                                                        this.data(pendanaanOutstanding, pendanaanOutstanding && pendanaanOutstanding.toLocaleString("IN"))

                                                )}
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="card-statistic">
                                <div className="">
                                    <div className="d-flex">
                                        <PendanaanSelesaiIcon className="text-primary material-icons mr-2" style={{ marginBlock: '4px' }} />
                                        <div className="d-flex flex-column gap-1">
                                            <p className="text-secondary d-flex gap-1 flex-1 mb-0">Pendanaan Selesai {' '}
                                                <span className="d-flex justify-content-center align-items-center" id="Popovers4" data-html="true" onMouseEnter={() => this.onHover('popoverSelesai')} onMouseLeave={() => this.onHoverLeave('popoverSelesai')}>

                                                    <i className="material-icons-outlined text-primary" style={{ fontSize: "16px" }}>
                                                        info
                                                    </i>
                                                </span>
                                                <Popover
                                                    // className="d-none d-sm-block"
                                                    placement="bottom"
                                                    isOpen={this.state.popoverSelesai}
                                                    target="Popovers4"
                                                    toggle={this.togglePopOver.bind(this, 'popoverSelesai')}
                                                >

                                                    <PopoverHeader className="popover-header dark-style" ></PopoverHeader>
                                                    <PopoverBody className="dark-style fs-n2">Total pendanaan proyek yang telah dikembalikan</PopoverBody>
                                                </Popover>

                                            </p>
                                            <h6 className="font-weight-semi-bold mb-0">
                                                {this.props.lender.isLoadingTotal ? (
                                                    <Skeleton />
                                                ) : (
                                                        this.data(pendanaanSelesai, pendanaanSelesai && pendanaanSelesai.toLocaleString("IN"))

                                                )}
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* row 2 */}

                    <div className="row ">
                        <div className="col-12 col-md-4 mb-3 mb-md-0">
                            <div className="card-statistic">
                                <div className="">
                                    <div className="d-flex">
                                        <TotalProfitIcon className="text-primary material-icons mr-2" style={{ marginBlock: '4px' }} />
                                        {/* <i className="text-primary material-icons mr-3">account_box</i> */}
                                        <div className="d-flex flex-column gap-1">
                                            <p className="text-secondary d-flex gap-1 flex-1 mb-0">Total Profit {' '}
                                                <span className="d-flex justify-content-center align-items-center" id="Popovers5" data-html="true" onMouseEnter={() => this.onHover('popoverTotalProfit')} onMouseLeave={() => this.onHoverLeave('popoverTotalProfit')}>

                                                    <i className="material-icons-outlined text-primary" style={{ fontSize: "16px" }}>
                                                        info
                                                    </i>
                                                </span>
                                                <Popover
                                                    // className="d-none d-sm-block"
                                                    placement="bottom"
                                                    isOpen={this.state.popoverTotalProfit}
                                                    target="Popovers5"
                                                    toggle={this.togglePopOver.bind(this, 'popoverTotalProfit')}
                                                >

                                                    <PopoverHeader className="popover-header dark-style" ></PopoverHeader>
                                                    <PopoverBody className="dark-style fs-n2">Imbal hasil dari seluruh proyek yg di danai oleh user</PopoverBody>
                                                </Popover>

                                            </p>
                                            <h6 className="font-weight-semi-bold mb-0">
                                                {this.props.lender.isLoadingTotal ? (
                                                    <Skeleton />
                                                ) : (
                                                        this.data(totalProfit, totalProfit && totalProfit.toLocaleString("IN"))

                                                )}
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="card-statistic">
                                <div className="">
                                    <div className="d-flex">
                                        <PembayaranJatuhTempoIcon className="text-primary material-icons mr-2" style={{ marginBlock: '4px' }} />

                                        <div className="d-flex flex-column gap-1">
                                            <p className="text-secondary d-flex gap-1 flex-1 mb-0">Pembayaran Jatuh Tempo {' '}
                                                <span className="d-flex justify-content-center align-items-center" id="Popovers6" data-html="true" onMouseEnter={() => this.onHover('popoverJatuhTempo')} onMouseLeave={() => this.onHoverLeave('popoverJatuhTempo')}>
                                                    <i className="material-icons-outlined text-primary" style={{ fontSize: "16px" }}>
                                                        info
                                                    </i>
                                                </span>
                                                <Popover
                                                    // className="d-none d-sm-block"
                                                    placement="bottom"
                                                    isOpen={this.state.popoverJatuhTempo}
                                                    target="Popovers6"
                                                    toggle={this.togglePopOver.bind(this, 'popoverJatuhTempo')}
                                                >

                                                    <PopoverHeader className="popover-header dark-style" ></PopoverHeader>
                                                    <PopoverBody className="dark-style fs-n2">Bagian dari outstanding, pembayaran yang belum dibayar oleh penerima dana namun sudah jatuh tempo</PopoverBody>
                                                </Popover>

                                            </p>
                                            <h6 className="font-weight-semi-bold mb-0">
                                                {this.props.lender.isLoadingTotal ? (
                                                    <Skeleton />
                                                ) : (
                                                        this.data(pembayaranJatuhTempo, pembayaranJatuhTempo && pembayaranJatuhTempo.toLocaleString("IN"))

                                                )}
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="card-statistic">
                                <div className="">
                                    <div className="d-flex">
                                        <PortfolioLancarIcon className="text-primary material-icons mr-2" style={{ marginBlock: '4px' }} />
                                        <div className="d-flex flex-column gap-1">
                                            <p className="text-secondary d-flex gap-1 flex-1 mb-0">Portofolio Lancar {' '}
                                                <span className="d-flex justify-content-center align-items-center" id="Popovers7" data-html="true" onMouseEnter={() => this.onHover('popoverPortfolioLancar')} onMouseLeave={() => this.onHoverLeave('popoverPortfolioLancar')}>
                                                    <i className="material-icons-outlined text-primary" style={{ fontSize: "16px" }}>
                                                        info
                                                    </i>
                                                </span>
                                                <Popover
                                                    // className="d-none d-sm-block"
                                                    placement="bottom"
                                                    isOpen={this.state.popoverPortfolioLancar}
                                                    target="Popovers7"
                                                    toggle={this.togglePopOver.bind(this, 'popoverPortfolioLancar')}
                                                >

                                                    <PopoverHeader className="popover-header dark-style" ></PopoverHeader>
                                                    <PopoverBody className="dark-style fs-n2">Besar persentase untuk seluruh proyek "lancar" yang di danai oleh user</PopoverBody>
                                                </Popover>

                                            </p>
                                            <h6 className="font-weight-semi-bold mb-0">
                                                {this.props.lender.isLoadingTotal ? (
                                                    <Skeleton />
                                                ) : (
                                                       percentageConvert(portfolioLancar)

                                                )}
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        lender: state.lender
    };
};
export default connect(mapStateToProps)(DasborTotal);
