import React from 'react'
import { Form, FormGroup, Label, FormText, CustomInput, Input, Button, FormFeedback } from "reactstrap";

export default function FileInput({ handler, title, fieldState, name, label, filled = false, onClickShowHandler, styleClass= '' }) {
    return (
        <FormGroup className={`position-relative ${styleClass}`}>
            <Label for={name}>{title}</Label>
            <CustomInput
                type="file"
                onChange={handler}
                name={name}
                id="exampleFile"
                label={label}
                accept="application/pdf"
            />
            {filled &&
                <Button className='position-absolute' style={{ zIndex: '20', fontSize: '14px', right: '36px', background: 'white', top: '35px', color: '#FF7A00', border: 'none', fontWeight: 700 }} onClick={onClickShowHandler}>Lihat</Button>
            }
            <FormText color="danger" style={{ fontSize: "11px" }}>
                {fieldState.error?.[name] === "" ? null : fieldState.error?.[name]}
            </FormText>
        </FormGroup>

    )

}