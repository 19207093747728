import React, { Component } from "react";
import { Button, FormText, Modal, ModalHeader, ModalBody } from "reactstrap";
import { checkPusdafilUpdate, getUser, postPusdafilUpdate } from "../../../redux/action/User";
import { LenderLend } from "../../../redux/action/Lender";
import { getInterestLend } from "../../../redux/action/Project";
import { DipayLogin, DipayPay, getUser as UserDipay } from "react-dipay-web-sdk";
import { LinkAccount, CreateTransaction } from "../../../redux/action/Dipay";
import { connect } from "react-redux";
import NumberFormat from "react-number-format";
import Swal from "sweetalert2";
import Skeleton from "react-loading-skeleton";
import { NavLink } from "react-router-dom";
import { validate } from "../../../library/validation/validate";
import { estimasiBunga, newInsuranceCalcule } from "../../../library/helper/helper";
import "../../../sass/loader.css";
import DetailBayar from "./AllProyekBayar-detailbayar";
import PilihBayar from "./AllProyekBayar-pilihbayar";
import ShowingAsuransi from "./AllProyekBayar-asuransi";
import DownloadDocumentSuperlender from "./AllProyekBayar-downloand";
import { DownloadDocument } from "../../../redux/action/Digisign";
import PengkinianData from "../../template/lender/pengkinian-data/PengkinianData";
import { Master, MasterPusdafil } from "../../../redux/action/Master";

class ProyekBayar extends Component {
    _isMounted = false;
    _timeoutId = null;

    constructor(props) {
        const dipay = JSON.parse(process.env.REACT_APP_DEV_DIPAY);
        super(props);
        this.state = {
            projectdet: [],
            borrower: [],
            nilai_penawaran_pinjaman: "",
            errNominal: "",
            modal: false,
            modalPengkinianData: false,
            setModal: false,
            popoverOpenProyek: false,
            activeTab: "1",
            saldo: "",
            kyc: "",
            update: "",
            proyek_id: "",
            dipay: false,
            indof: true,
            openDipay: false,
            setOpenDipay: false,
            openDP: false,
            setOpenDP: false,
            response: "",
            dipay_key: "",
            saldoDipay: "",
            id_lender: "",
            jenis_pengguna: "",
            checked: false,
            devDipay: dipay,
            asuransi: 0,
            status_asuransi: "",
            userAktivasi: "",
            identityUser: "",
            bungaPajak: "",
            bungaAfterPajak: "",
            bungaBeforePajak: "",
            estimasiBunga: 0,
            isLoadingDownload: false
        };
        // this.toggle = this.toggle.bind(this);
    }
    async componentDidMount() {
        this._isMounted = true;

        await this.props.dispatch(getUser(localStorage.id));


        if (this._isMounted) {
            this.setState({
                saldo: this.props.user.userList.va_saldo && this.props.user.userList.va_saldo.nilai_saldo,
                update: this.props.user.userList.lender_id && this.props.user.userList.lender_id.update_lender,
                id_lender: this.props.user.userList.lender_id && this.props.user.userList.lender_id._id,
                jenis_pengguna: this.props.user.userList.lender_id && this.props.user.userList.lender_id.jenis_pengguna,
                // kyc: this.props.user.userList.userAktivasi, //user mau verifikasi email
                kyc: this.props.user.userList.lender_id && this.props.user.userList.lender_id.admin_kyc,
                dipay_key: this.props.user.userList.dipay_key,
                proyek_id: this.props.proyek_id,
                userAktivasi: this.props.user.userList.userAktivasi,
                identityUser: parseInt(this.props.user.userList.lender_id.no_ktp)
            });

            if (this.state.dipay_key !== undefined) {
                this.userDipay();
            }
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    loading = (data) => {
        if (this.props.user.isLoadingGetUser !== true) {
            return data;
        } else {
            return <Skeleton width={200} />;
        }
    };
    //pilihan pembayaran
    handleOptionButton = () => {
        if (!this.state.indof) {
            this.setState({
                dipay: !this.state.dipay,
                indof: !this.state.indof
            });
        } else {
            this.setState({
                dipay: !this.state.dipay,
                indof: !this.state.indof
            });
        }
        // kalo false radio button on
        const sisa = this.props.project.nilai_pengajuan_pinjaman - this.props.project.nilai_total_peminjam;
        var value = this.state.nilai_penawaran_pinjaman;
        var changeError = "errNominal";
        var type = "danaiIndofund";

        if (this.state.dipay === false) {
            if (this.state.dipay_key === undefined) {
                this.setState({
                    errNominal: "Akun Dipay Anda belum aktif, pilih metode lain"
                });
            } else {
                this.setState({
                    [changeError]: validate(type, value, sisa, this.state.saldoDipay)
                });
            }
        } else {
            this.setState({
                [changeError]: validate(type, value, sisa, this.state.saldo)
            });
        }
    };
    async calculateEstimasiBung() {
        let value = parseInt(this.state.nilai_penawaran_pinjaman.replace(/\D/g, "").replace(/^0+/, ""));
        if (value >= 50000) {
            await this.props.dispatch(getInterestLend(this.state.proyek_id, value));
            this.setState({
                estimasiBunga: this.props.projectInterest.interestBeforePph
            });
        }
    }
    onChange = (e, type, changeError) => {
        let value = e.target.value;
        const sisa = this.props.project.nilai_pengajuan_pinjaman - this.props.project.nilai_total_peminjam;

        const jenis_pinjaman = this.props.project.jenis_pinjaman;
        if (this._timeoutId) clearTimeout(this._timeoutId);
        this._timeoutId = setTimeout(() => {
            this.calculateEstimasiBung();
        }, 500);

        if (this.state.dipay === true) {
            this.setState({ [e.target.name]: e.target.value }, () => {
                this.setState({
                    [changeError]: validate(type, value, sisa, this.state.saldoDipay)
                });
            });
        } else {
            this.setState({ [e.target.name]: e.target.value }, () => {
                this.setState({
                    [changeError]: validate(type, value, sisa, this.state.saldo, jenis_pinjaman)
                });
            });
        }
    };

    onKeyDown = (e) => {
        if (e.keyCode === 8) {
            this.setState({
                errNominal: "",
                nilai_penawaran_pinjaman: "",
                asuransi: 0
            });
            //  console.log('delete');
        }
    };

    nilaiAsuransi = () => {
        const valuePinjaman = parseInt(this.state.nilai_penawaran_pinjaman.replace(/\D/g, "").replace(/^0+/, ""));
        if (this.state.checked) {
            return newInsuranceCalcule(this.props.project.tipe_pinjaman, this.props.project.jangka_waktu_pinjaman, valuePinjaman);
        } else {
            return 0;
        }
    };

    total_pendanaan() {
        const valuePinjaman = parseInt(this.state.nilai_penawaran_pinjaman.replace(/\D/g, "").replace(/^0+/, ""));
        return valuePinjaman + this.nilaiAsuransi();
    }

    togglemodal = async () => {
        // //get bunga
        var nominal = parseInt(this.state.nilai_penawaran_pinjaman.replace(/\D/g, "").replace(/^0+/, ""));
        if (isNaN(nominal)) {
            nominal = 0;
        }
        // console.log(nominal)
        // console.log(this.state.nilai_penawaran_pinjaman)
        if (nominal < 50000) {
            Swal.fire({
                title: "Gagal Mendanai",
                text: "Nominal Kurang Dari 50.000",
                icon: "warning",
                button: "ok"
            });
        } else {
            await this.props.dispatch(getInterestLend(this.state.proyek_id, nominal));
        }
        //end get bunga
        if (!this.state.nilai_penawaran_pinjaman) {
            Swal.fire({
                title: "Gagal Mendanai",
                text: "Isi nominal terlebih dahulu",
                icon: "warning",
                button: "ok"
            });
        } else {
            if (this.state.errNominal !== 0) {
                Swal.fire({
                    title: "Gagal Mendanai",
                    text: `${this.state.errNominal}`,
                    icon: "warning",
                    button: "ok"
                });
            } else if (this.state.dipay === false && this.total_pendanaan() > this.state.saldo) {
                Swal.fire({
                    title: "Gagal Mendanai",
                    text: "Nominal melebihi saldo",
                    icon: "warning",
                    button: "ok"
                });
            } else if (this.state.dipay === true && this.total_pendanaan() > this.state.saldoDipay) {
                Swal.fire({
                    title: "Gagal Mendanai",
                    text: "Nominal melebihi saldo",
                    icon: "warning",
                    button: "ok"
                });
            } else {
                this.toggleModalPengkinianData()


            }
        }
    };
    //dana proteksi
    handleCheck = () => {

        this.setState({
            checked: !this.state.checked
        });
    };
    toggleModalPengkinianData = async () => {
        this.checkPusdafil()
    }
    submitPusdafilUpdate = async (body) => {
        Swal.fire({
            title: "Konfirmasi",
            text: "Apakah Data Sudah Benar ?",
            icon: "info",
            showCancelButton: true,
            confirmButtonText: 'Ya',
            denyButtonText: 'Tidak',
            button: "ok"
        }).then(async (result) => {
            if (result.isConfirmed) {
                await this.props.dispatch(postPusdafilUpdate("Lender", localStorage.getItem('id'), body)).then(() => {
                    this.setState({ modalPengkinianData: !this.state.modalPengkinianData })

                    Swal.fire({
                        title: "Success",
                        text: "Pengkinian Data Berhasil",
                        icon: "success",
                        button: "ok"
                    }).then((result) => {

                        this.checkPusdafil()

                    })
                })

            }
        }).catch(() => {
            Swal.fire({
                title: "Error",
                text: "Pengkinian Data Gagal",
                icon: "error",
                button: "ok"
            })
        })

    }
    checkPusdafil = async () => {
        await this.props.dispatch(checkPusdafilUpdate("Lender", localStorage.getItem('id'))).then(async item => {
            if (this.props.user.checkPusdafil?.is_need_update)
                await this.props.dispatch(MasterPusdafil()).then(() => {
                    this.setState({ modalPengkinianData: !this.state.modalPengkinianData })

                });
            else {
                if (this.state.dipay !== true) {
                    this.setState({
                        modal: !this.state.modal,
                        setModal: !this.state.setModal,
                        bungaAfterPajak: this.props.projectInterest.interestAfterPph,
                        bungaBeforePajak: this.props.projectInterest.interestBeforePph
                    });
                }
                else this.setState({
                    openDP: !this.state.openDP,
                    setOpenDP: !this.state.setOpenDP
                });

            }
        });
    }
    authMendanai() {
        if (this.state.kyc === false && this.state.update === false) {
            return (
                <FormText color="danger">
                    Anda belum bisa mendanai karena data Anda belum lengkap, silahkan lengkapi data ada di{" "}
                    <NavLink
                        to={{
                            pathname: "/lender/akun/" + this.state.id_lender,
                            state: {
                                jenis: `${this.state.jenis_pengguna}`,
                                jenisMaster: `${this.state.jenis_pengguna}`
                            }
                        }}
                        className="btn btn-light ml-auto text-primary px-4 font-weight-semi-bold">
                        Disini
                    </NavLink>
                </FormText>
            );
        } else if (this.state.update === true && this.state.kyc === false) {
            return <FormText color="danger">Anda belum bisa mendanai karena data sedang di verif oleh admin</FormText>;
        } else if (this.state.kyc === false && this.state.update === true) {
            return (
                <FormText color="danger">
                    Anda belum bisa mendanai karena data Anda belum lengkap, silahkan lengkapi data ada di{" "}
                    <NavLink
                        to={{
                            pathname: "/lender/akun/" + this.state.id_lender,
                            state: {
                                jenis: `${this.state.jenis_pengguna}`,
                                jenisMaster: `${this.state.jenis_pengguna}`
                            }
                        }}
                        className="text-primary  font-weight-semi-bold">
                        Disini
                    </NavLink>
                </FormText>
            );
        }
    }
    authDisabled(project) {
        if (
            project.status_proyek === "Pembayaran cicilan" ||
            project.status_proyek === "Terdanai sebagian" ||
            project.status_proyek === "Proyek sedang berjalan" ||
            project.status_proyek === "Proyek selesai" ||
            this.state.update === false ||
            this.state.kyc === false
        ) {
            return true;
        } else {
            return false;
        }
    }
    componentWillReceiveProps(newProps) {
        this.setState({
            proyek_id: newProps.proyek_id
        });
    }

    // download document super lender
    async handleDownloadDocument() {
        this.setState({
            isLoadingDownload: true
        });
        await this.props
            .dispatch(DownloadDocument(this.state.proyek_id))
            .then((res) => {
                this.setState({
                    isLoadingDownload: false
                });
                if (res.value.data.JSONFile.result === "05") {
                    Swal.fire({
                        title: "Gagal",
                        text: `${res.value.data.JSONFile.notif}`,
                        icon: "warning",
                        button: "ok"
                    });
                } else if (res.value.data.JSONFile.result === "12") {
                    Swal.fire({
                        title: "Gagal",
                        text: `${res.value.data.JSONFile.notif}`,
                        icon: "warning",
                        button: "ok"
                    });
                } else {
                    const pdf = res.value.data.JSONFile.file;
                    const linkSource = `data:application/pdf;base64,${pdf}`;
                    const downloadLink = document.createElement("a");
                    const fileName = "Perjanjian pinjaman.pdf";

                    downloadLink.href = linkSource;
                    downloadLink.download = fileName;
                    downloadLink.click();
                }
            })
            .catch((err) => {
                // console.log(err.response)
                this.setState({
                    isLoadingDownload: false
                });
            });
    }

    //open modal dipay
    toggleModalAktivasi() {
        this.setState({
            openDipay: !this.state.openDipay,
            setOpenDipay: !this.state.setOpenDipay
        });
    }

    toggleModalPaymentDipay() {
        if (!this.state.nilai_penawaran_pinjaman) {
            Swal.fire({
                title: "Gagal Mendanai",
                text: "Isi nominal terlebih dahulu",
                icon: "warning",
                button: "ok"
            });
        } else {
            if (this.state.errNominal !== 0) {
                Swal.fire({
                    title: "Gagal Mendanai",
                    text: `${this.state.errNominal}`,
                    icon: "warning",
                    button: "ok"
                });
            } else if (this.state.dipay === false && this.state.nilai_penawaran_pinjaman > this.state.saldo) {
                Swal.fire({
                    title: "Gagal Mendanai",
                    text: "Nominal melebihi saldo",
                    icon: "warning",
                    button: "ok"
                });
            } else if (this.state.dipay === true && this.state.nilai_penawaran_pinjaman > this.state.saldoDipay) {
                Swal.fire({
                    title: "Gagal Mendanai",
                    text: "Nominal melebihi saldo",
                    icon: "warning",
                    button: "ok"
                });
            } else {
                this.toggleModalPengkinianData()
            }
        }
    }
    // end open modal

    //aktivasi dan bayar dipay
    LinkDipay = async (secretkey) => {
        //send backend
        await this.props
            .dispatch(
                LinkAccount({
                    dipay_key: secretkey
                })
            )
            .then((res) => {
                // console.log(res)
            })
            .catch((err) => {
                // console.log(err)
            });
    };
    userDipay = async () => {
        //get user from Dipay
        await UserDipay("indofund", this.state.dipay_key, this.state.devDipay)
            .then((res) => {
                this.setState({
                    saldoDipay: res.balance.primary //change saldo dipay
                });
            })
            .catch((err) => {
                //  console.log(err)
            });
    };
    PaymentDipay = async (productCodeDipay, signatureDipay, totalPriceDipay, transactionDateDipay, asuransi) => {
        // console.log(productCodeDipay, signatureDipay, totalPriceDipay, transactionDateDipay)
        // console.log(signature)
        this.setState({
            openDP: false,
            setOpenDP: false
        });
        await this.props
            .dispatch(
                CreateTransaction(this.state.proyek_id, {
                    nilai_penawaran_pinjaman: totalPriceDipay,
                    signature: signatureDipay,
                    transactionDate: transactionDateDipay,
                    productCode: productCodeDipay,
                    // asuransi:this.state.asuransi,
                    asuransi: this.state.checked ? asuransi : 0,
                    status_asuransi: this.state.checked ? "Di bayar" : this.state.status_asuransi,
                    metode_pembayaran: "Dipay"
                })
            )
            .then(async (res) => {
                // console.log(res);
                if (res.value.data.handleSigningData.JSONFile.result === "05") {
                    // Swal.fire({
                    //   title: "Gagal",
                    //   text: `${res.value.data.handleSigningData.JSONFile.notif}`,
                    //   icon: "warning",
                    //   button: "ok",
                    // });
                    Swal.fire({
                        title: "Berhasil",
                        text: "Pendanaan anda berhasil. ",
                        // Silahkan lakukan tanda tangan perjanjian pada menu 'Tanda Tangan Digital' pada dashboard,
                        icon: "success",
                        button: "ok"
                    }).then(() => {
                        window.location.replace("/lender/portofolio");
                        // window.location.href = "/proyek";
                    });
                } else if (res.value.data.handleSigningData.JSONFile.result === "30") {
                    Swal.fire({
                        title: "Gagal",
                        text: `${res.value.data.handleSigningData.JSONFile.notif}`,
                        icon: "warning",
                        button: "ok"
                    });
                } else if (res.value.data.handleSigningData.JSONFile.result === "12") {
                    Swal.fire({
                        title: "Gagal",
                        text: `${res.value.data.handleSigningData.JSONFile.notif}`,
                        icon: "warning",
                        button: "ok"
                    });
                } else if (res.value.data.handleSigningData.JSONFile.result === "28") {
                    Swal.fire({
                        title: "Gagal",
                        text: `${res.value.data.handleSigningData.JSONFile.notif}`,
                        icon: "warning",
                        button: "ok"
                    });
                } else if (res.value.data.handleSigningData.JSONFile.result === "06") {
                    Swal.fire({
                        title: "Gagal",
                        text: `${res.value.data.handleSigningData.JSONFile.notif}`,
                        icon: "warning",
                        button: "ok"
                    });
                } else if (res.value.data.handleSigningData.JSONFile.result === "14") {
                    Swal.fire({
                        title: "Gagal",
                        text: `${res.value.data.handleSigningData.JSONFile.notif}`,
                        icon: "warning",
                        button: "ok"
                    });
                } else if (res.value.data.handleSigningData.JSONFile.result === "28") {
                    Swal.fire({
                        title: "Gagal",
                        text: `${res.value.data.handleSigningData.JSONFile.notif}`,
                        icon: "warning",
                        button: "ok"
                    });
                } else if (res.value.data.handleSigningData.JSONFile.result === "06") {
                    Swal.fire({
                        title: "Gagal",
                        text: `${res.value.data.handleSigningData.JSONFile.notif}`,
                        icon: "warning",
                        button: "ok"
                    });
                } else if (res.value.data.handleSigningData.JSONFile.result === "14") {
                    Swal.fire({
                        title: "Gagal",
                        text: `${res.value.data.handleSigningData.JSONFile.notif}`,
                        icon: "warning",
                        button: "ok"
                    });
                } else if (res.value.data.handleSigningData.JSONFile.result === "12") {
                    Swal.fire({
                        title: "Gagal",
                        text: `${res.value.data.handleSigningData.JSONFile.notif}`,
                        icon: "warning",
                        button: "ok"
                    });
                } else if (res.value.data.handleSigningData.JSONFile.result === "00") {
                    localStorage.setItem("currentPage", window.location.pathname);
                    localStorage.setItem("userAktivasi", "true");
                    window.location.replace(res.value.data.handleSigningData.JSONFile.link);
                } else {
                    Swal.fire({
                        title: "Berhasil",
                        text: "Pendanaan anda berhasil. ",
                        // Silahkan lakukan tanda tangan perjanjian pada menu 'Tanda Tangan Digital' pada dashboard,
                        icon: "success",
                        button: "ok"
                    }).then(() => {
                        window.location.replace("/lender/portofolio");
                        // window.location.href = "/proyek";
                    });
                }
                // console.log(this.props.dipay.isLoadingCreate)
                // Swal.fire({
                //   title: "Berhasil",
                //   text: "Pendanaan anda berhasil",
                //   icon: "success",
                //   button: "ok",
                // }).then(() => {
                //   window.location.reload();
                // });
            })
            .catch((err) => {
                // if (err.response.status === 400) {
                //   Swal.fire({
                //     title: "Gagal ",
                //     text: `${err.response.data.message}`,
                //     icon: "warning",
                //     button: "ok",
                //   });
                // } else if (err.response.status === 404) {
                //   Swal.fire({
                //     title: "Gagal",
                //     text: `${err.response.data.message}`,
                //     icon: "warning",
                //     button: "ok",
                //   });
                // } else if (err.response.status === 500) {
                //   Swal.fire({
                //     title: "Gagal",
                //     text: `${err.response.data.message}`,
                //     icon: "warning",
                //     button: "ok",
                //   }).then(() => {
                //     window.location.reload();
                //   });
                // }
            });
    };
    loadingBayarDipay() {
        if (this.props.dipay.isLoadingCreate === true) {
            Swal.fire({
                html: '<div><span class="loader" style="width: 50px;height: 50px; border: 5px solid #f3f3f3;border-top: 7px solid orange; margin-left: -23px"></span></div>',
                width: 30,
                padding: "3em",
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: false,
                backdrop: `
                rgba(255,255,255, 0.8)
                `
            });
        }
    }
    //end aktivasi dan bayar dipay

    //bayar dompet
    handleBayar = async (asuransi) => {
        await this.props
            .dispatch(
                LenderLend(
                    this.state.proyek_id,

                    {
                        nilai_penawaran_pinjaman: parseInt(this.state.nilai_penawaran_pinjaman.replace(/\D/g, "").replace(/^0+/, "")),
                        metode_pembayaran: "Saldo",
                        asuransi: this.state.checked ? asuransi : 0,
                        status_asuransi: this.state.checked ? "Di bayar" : this.state.status_asuransi
                    }
                )
            )
            .then(async (res) => {
                if (res.value.data.JSONFile) {
                    if (res.value.data.JSONFile.result === "05") {
                        Swal.fire({
                            title: "Berhasil",
                            text: "Pendanaan anda berhasil. ",
                            // Silahkan lakukan tanda tangan perjanjian pada menu 'Tanda Tangan Digital' pada dashboard,
                            icon: "success",
                            button: "ok"
                        }).then(() => {
                            window.location.replace("/lender/portofolio");
                            // window.location.href = "/proyek";
                        });
                        // Swal.fire({
                        //   title: "Gagal",
                        //   text: `${res.value.data.JSONFile.notif}`,
                        //   icon: "warning",
                        //   button: "ok",
                        // });
                    } else if (res.value.data.JSONFile.result === "30") {
                        Swal.fire({
                            title: "Gagal",
                            text: `${res.value.data.JSONFile.notif}`,
                            icon: "warning",
                            button: "ok"
                        });
                    } else if (res.value.data.JSONFile.result === "12") {
                        Swal.fire({
                            title: "Gagal",
                            text: `${res.value.data.JSONFile.notif}`,
                            icon: "warning",
                            button: "ok"
                        });
                    } else if (res.value.data.JSONFile.result === "28") {
                        Swal.fire({
                            title: "Gagal",
                            text: `${res.value.data.JSONFile.notif}`,
                            icon: "warning",
                            button: "ok"
                        });
                    } else if (res.value.data.JSONFile.result === "06") {
                        Swal.fire({
                            title: "Gagal",
                            text: `${res.value.data.JSONFile.notif}`,
                            icon: "warning",
                            button: "ok"
                        });
                    } else if (res.value.data.JSONFile.result === "14") {
                        Swal.fire({
                            title: "Gagal",
                            text: `${res.value.data.JSONFile.notif}`,
                            icon: "warning",
                            button: "ok"
                        });
                    } else if (res.value.data.JSONFile.result === "28") {
                        Swal.fire({
                            title: "Gagal",
                            text: `${res.value.data.JSONFile.notif}`,
                            icon: "warning",
                            button: "ok"
                        });
                    } else if (res.value.data.JSONFile.result === "06") {
                        Swal.fire({
                            title: "Gagal",
                            text: `${res.value.data.JSONFile.notif}`,
                            icon: "warning",
                            button: "ok"
                        });
                    } else if (res.value.data.JSONFile.result === "14") {
                        Swal.fire({
                            title: "Gagal",
                            text: `${res.value.data.JSONFile.notif}`,
                            icon: "warning",
                            button: "ok"
                        });
                    } else if (res.value.data.JSONFile.result === "12") {
                        Swal.fire({
                            title: "Gagal",
                            text: `${res.value.data.JSONFile.notif}`,
                            icon: "warning",
                            button: "ok"
                        });
                    } else if (res.value.data.JSONFile.result === "00") {
                        localStorage.setItem("currentPage", window.location.pathname);
                        localStorage.setItem("userAktivasi", "true");
                        window.location.replace(res.value.data.JSONFile.link);
                    } else {
                        Swal.fire({
                            title: "Berhasil",
                            text: "Pendanaan anda berhasil. ",
                            // Silahkan lakukan tanda tangan perjanjian pada menu 'Tanda Tangan Digital' pada dashboard,
                            icon: "success",
                            button: "ok"
                        }).then(() => {
                            window.location.replace("/lender/portofolio");
                            // window.location.href = "/proyek";
                        });
                        this.setState({
                            modal: false
                        });
                    }
                } else {
                    Swal.fire({
                        title: "Berhasil",
                        text: "Pendanaan anda berhasil. ",
                        // Silahkan lakukan tanda tangan perjanjian pada menu 'Tanda Tangan Digital' pada dashboard,
                        icon: "success",
                        button: "ok"
                    }).then(() => {
                        window.location.replace("/lender/portofolio");
                        // window.location.href = "/proyek";
                    });
                    this.setState({
                        modal: false
                    });
                }
            })
            .catch((err) => {
                console.log(err);
                // if (err.response.status === 400) {
                //   Swal.fire({
                //     title: "Gagal ",
                //     text: `${err.response.data.message}`,
                //     icon: "warning",
                //     button: "ok",
                //   })
                // } else if (err.response.status === 401) {
                //   Swal.fire({
                //     title: "Gagal",
                //     text: `${err.response.data.message}`,
                //     icon: "warning",
                //     button: "ok",
                //   });
                // } else if (err.response.status === 500) {
                //   Swal.fire({
                //     title: "Gagal",
                //     text: `${err.response.data.message}`,
                //     icon: "warning",
                //     button: "ok",
                //   });
                // }
            });
        // console.log(this.state)
    };

    render() {
        // console.log(this.props.dipay.isLoadingCreate);
        const project = this.props.project;
        const nominal = this.state.nilai_penawaran_pinjaman.replace(/\D/g, "");
        // const bungaPerkiraan = estimasiBunga(
        //   project.jenis_pinjaman,
        //   project.nilai_pengajuan_pinjaman,
        //   project.suku_bunga_pinjaman,
        //   project.suku_bunga_konsumtif,
        //   project.suku_bunga_micro,
        //   project.jangka_waktu_pinjaman,
        //   project.spread_bunga,
        //   parseInt(nominal)
        // );
        const nilaiAsuransi = () => {
            if (this.state.checked) {
                return newInsuranceCalcule(project.tipe_pinjaman, project.jangka_waktu_pinjaman, parseInt(nominal));
            } else {
                return 0;
            }
        };

        // const total_pendanaan = parseInt(nominal) + nilaiAsuransi();

        this.loadingBayarDipay(); //loading bayar dipay

        return (
            <div
                className={this.props.class}
                style={{
                    position: "sticky",
                    // position: "-webkit-sticky",
                    height: "100%",
                    top: "8rem"
                }}>
                <div className="card border-0 shadow" style={{ overflow: "auto" }}>
                    <form action="">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-12 col-md-7">
                                    <p className="text-secondary mb-2">Nominal yang akan dipinjamkan</p>
                                    <div className="form-group ">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <div className="input-group-text">
                                                    <span className="font-weight-semi-bold">Rp</span>
                                                </div>
                                            </div>
                                            <NumberFormat
                                                onChange={(e) =>
                                                    this.onChange(
                                                        e,
                                                        project.jenis_pinjaman === "Micro Bullet Reguler" ||
                                                            project.nilai_pengajuan_pinjaman % 50000 !== 0
                                                            ? "danaiNoValidate"
                                                            : "danaiIndofund",
                                                        "errNominal"
                                                    )
                                                }
                                                // onKeyDown={this.onKeyDown}
                                                autoComplete="off"
                                                placeholder="0"
                                                className="form-control text-right"
                                                name="nilai_penawaran_pinjaman"
                                                thousandSeparator={"."}
                                                decimalSeparator={","}
                                                allowNegative={false}
                                                decimalScale={0}
                                                disabled={this.authDisabled(project)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-5">
                                    <p className="text-secondary mb-2">Estimasi Bunga</p>
                                    <p className="fs-1 text-primary font-weight-semi-bold mb-0 pt-2" id="output">
                                        { } Rp {this.props.isLoadingPreLending ? "loading.." : this.state.estimasiBunga.toLocaleString("IN")}
                                    </p>
                                </div>
                            </div>
                            <FormText color="danger" className="mb-3">
                                {this.state.errNominal === 0 ? null : this.state.errNominal}
                            </FormText>
                            {/* {!project.premium_lender && (
                                <ShowingAsuransi
                                    isOpen={this.state.dipay === false ? true : false}
                                    asuransi={nilaiAsuransi().toLocaleString("IN")}
                                    onChange={this.handleCheck}
                                    defaultChecked={this.state.checked}
                                />
                            )} */}
                            <PilihBayar
                                judul={"Indofund"}
                                onChange={this.handleOptionButton}
                                defaultChecked={this.state.indof || ""}
                                saldo={this.state.saldo}
                                img={process.env.PUBLIC_URL + "../images/logo.png"}
                            />

                            {project.premium_lender ? (
                                <DownloadDocumentSuperlender
                                    onClick={this.handleDownloadDocument.bind(this)}
                                    isLoading={this.state.isLoadingDownload}
                                />
                            ) : (
                                <PilihBayar
                                    judul={"Dipay"}
                                    dipayKey={this.state.dipay_key}
                                    dipayAktivasi={this.toggleModalAktivasi.bind(this)}
                                    onChange={this.handleOptionButton}
                                    defaultChecked={this.state.dipay || ""}
                                    saldoDipay={this.state.saldoDipay}
                                    img={process.env.PUBLIC_URL + "../images/partners-icon/logo-dipay-text.svg"}
                                />
                            )}

                            {this.props.pinjaman === "Employee Loan" ? (
                                ""
                            ) : (
                                <p className="fs-n1">
                                    Harap membaca dokumen{" "}
                                    <a
                                        className="font-weight-semi-bold text-primary"
                                        href={project.prospectus_path}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{ cursor: "pointer" }}>
                                        Product Disclosure Statement
                                    </a>{" "}
                                    dari tiap produk, terutama yang berhubungan dengan aturan dan resiko pendanaan.
                                </p>
                            )}
                            <Button
                                disabled={this.authDisabled(project)}
                                onClick={this.state.dipay !== true ? this.togglemodal.bind(this) : this.toggleModalPaymentDipay.bind(this)}
                                color="primary"
                                className="d-flex gap-2 justify-content-between align-items-center btn btn-lg px-5">
                                {(this.props.user.isLoadingCheckPusdafil || this.props.master.isLoading) &&
                                    <span className="loader"></span>
                                } {' '}
                                Danai Sekarang
                            </Button>

                            {this.authMendanai()}
                        </div>
                    </form>
                </div>
                <PengkinianData loading={this.props.user.isLoadingPostPusdafil} onSubmit={this.submitPusdafilUpdate} master={this.props.master} isOpen={this.state.modalPengkinianData} lender={this.props.user.userList.lender_id} toggle={() => {
                    this.setState({ modalPengkinianData: !this.state.modalPengkinianData })
                }} jenis_pengguna={this.state.jenis_pengguna} />

                <Modal isOpen={this.state.modal} toggle={this.togglemodal.bind(this)} className="modal-dialog modal-dialog-centered" tabIndex="-1">
                    <ModalHeader toggle={this.togglemodal.bind(this)}>
                        <div className="text-center flex-fill">
                            <h5 className="modal-title font-weight-bold">Konfirmasi Pembayaran </h5>
                        </div>
                    </ModalHeader>
                    <ModalBody>
                        <div className="mb-5">
                            <p className="text-secondary mb-1">Proyek</p>
                            <p className="fs-1 mb-0">{" [ " + project.tipe_pinjaman + " ] " + project.nama_pinjaman}</p>
                        </div>
                        <div className="mb-5">
                            <DetailBayar nameTransaction={"Jumlah Pendanaan"} nominal={this.state.nilai_penawaran_pinjaman} />
                            {/* {!project.premium_lender && <DetailBayar nameTransaction={"Asuransi"} nominal={nilaiAsuransi()} />} */}
                            <DetailBayar nameTransaction={"Estimasi Bunga (Sebelum Pajak)"} nominal={this.state.bungaBeforePajak} />
                            <DetailBayar nameTransaction={"Bunga Setelah Pajak"} nominal={this.state.bungaAfterPajak} />
                            <hr />
                            <DetailBayar total={true} nameTransaction={"Total Pembayaran"} nominal={this.total_pendanaan()} />
                        </div>

                        {this.props.lender.isLoadingLending ? (
                            <Button color="primary" className="btn btn-block btn-lg" disabled onClick={this.handleBayar}>
                                <span className="loader"></span>
                            </Button>
                        ) : (
                            <Button color="primary" className="btn btn-block btn-lg" onClick={() => this.handleBayar(nilaiAsuransi())}>
                                Bayar
                            </Button>
                        )}
                        <Button color="outline-primary" data-dismiss="modal" className="btn btn-block btn-lg" onClick={this.togglemodal.bind(this)}>
                            Batal
                        </Button>
                    </ModalBody>
                </Modal>

                <DipayLogin
                    clientId="indofund" // Contact us for your client ID
                    open={this.state.openDipay}
                    onClose={this.toggleModalAktivasi.bind(this)}
                    onSuccess={(data) => this.LinkDipay(data.secretKey)}
                    dev={this.state.devDipay}
                    identificationNumber={this.state.identityUser}
                //data.balance.primary
                // onSuccess={data => console.log(data)}
                />

                <DipayPay
                    clientId="indofund" // Contact us for your Client ID
                    secretKey={this.state.dipay_key} // To determine user
                    open={this.state.openDP}
                    onClose={this.toggleModalPaymentDipay.bind(this)}
                    onSuccess={(data) =>
                        this.PaymentDipay(
                            data.prePayment.productCode,
                            data.prePayment.signature,
                            data.prePayment.totalPrice,
                            data.prePayment.transactionDate,
                            nilaiAsuransi()
                        )
                    }
                    // onSuccess={data => console.log(data)}
                    // productCode={this.state.proyek_id}
                    productCode={project.nama_pinjaman}
                    amount={this.total_pendanaan()}
                    dev={this.state.devDipay}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        lender: state.lender,
        dipay: state.dipay,
        digisign: state.digisign,
        projectInterest: state.project.projectInterest,
        master: state.master,
    };
};
export default connect(mapStateToProps)(ProyekBayar);
