import React, { useState } from "react";
import Select from "react-select"; //select option search
import Camera from "react-html5-camera-photo";
import NumberFormat from "react-number-format";
import "react-html5-camera-photo/build/css/index.css";
import {
    FormGroup,
    FormText,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Label,
    Input,
    Button,
    CustomInput,
    Modal,
    ModalHeader,
    ModalBody,
    Popover,
    PopoverHeader,
    PopoverBody,
    Tooltip
} from "reactstrap";
import { selectSearch, selectOption, selectOptionWithTooltip } from "./option";
import Location from "../../component/assets/svg/Location"; //icon svg
import ImagePreview from "./ImagePreview"; //img preview camera
import "./Components.scss";
import { numberInputValidate } from "../helper/helper";

function Inputs(props) {
    return (
        <FormGroup>
            <Label for={props.forLabel}>{props.label}</Label>
            <Input
                onChange={props.onChange}
                type={props.type}
                name={props.name}
                placeholder={props.placeholder}
                onKeyDown={props.onKeydown}
                readOnly={props.readOnly}
                maxLength={props.maxLength}
                defaultValue={props.value || ""}
                autoComplete="off"
            />
            <FormText color="danger" style={{fontSize:'11px'}}>
                {props.info === undefined ? null : (
                    <div>
                        {props.info} <br />
                    </div>
                )}

                {props.error === 0 ? null : props.error}
            </FormText>
        </FormGroup>
    );
}

function InputIcon(props) {
    return (
        <FormGroup className={`${props.noMargin ? 'mb-1' : ''}`}>
            <Label for={props.forLabel}>{props.label}</Label>
            <InputGroup>
                <InputGroupAddon addonType="prepend">
                    <InputGroupText>{props.icon}</InputGroupText>
                </InputGroupAddon>
                <Input
                    autoComplete="off"
                    onChange={props.onChange}
                    type={props.type}
                    name={props.name}
                    placeholder={props.placeholder}
                    maxLength={props.maxLength}
                    className={props.className}
                    value={props.value}
                    defaultValue={props.value || ""}
                    readOnly={props.readOnly}
                    onKeyPress={props.onKeyPress}
                />

                {props.password !== true ? null : (
                    <InputGroupAddon addonType="prepend">
                        <Button color="white" onClick={props.clickShow} className="password-eye">
                            {props.hidden === false ? (
                                <i className="fa fa-eye" aria-hidden="true" />
                            ) : (
                                <i className="fa fa-eye-slash" aria-hidden="true" />
                            )}
                        </Button>
                    </InputGroupAddon>
                )}
            </InputGroup>
            <FormText color="danger">
                {props.info === undefined ? null : (
                    <div>
                        {props.info} <br />
                    </div>
                )}

                {props.error === 0 ? null : props.error}
            </FormText>
        </FormGroup>
    );
}

function InputNominal(props) {
    return (
        <FormGroup>
            <Label for="nominal">{props.label}</Label>
            <InputGroup>
                <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                        <span className="font-weight-semi-bold">Rp</span>
                    </InputGroupText>
                </InputGroupAddon>
                <NumberFormat
                    onChange={props.onChange}
                    // onKeyDown={this.onKeyDown}
                    autoComplete="off"
                    placeholder="0"
                    type="text"
                    className="form-control text-right"
                    name={props.name}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    allowNegative={false}
                    decimalScale={0}
                />
            </InputGroup>
            <FormText color="danger">
                {props.info === undefined ? null : (
                    <div>
                        {props.info} <br />
                    </div>
                )}
                {props.error === 0 ? null : props.error}
            </FormText>
        </FormGroup>
    );
}

function InputTextArea(props) {
    return (
        <FormGroup>
            <Label for="exampleText">{props.label}</Label>
            <Input type="textarea" onChange={props.onChange} rows="8" name={props.name} id="exampleText" />
            <FormText color="danger">{props.error === 0 ? null : props.error}</FormText>
        </FormGroup>
    );
}

function UploadDoc(props) {
    return (
        <FormGroup>
            <Label for="exampleFile">{props.label}</Label>
            <CustomInput type="file" onChange={props.onChange} name={props.name} id="exampleFile" accept="application/pdf" />
            <FormText color="danger">Dokumen dalam bentuk PDF</FormText>
        </FormGroup>
    );
}

function SelectOptions(props) {
    return (
        <FormGroup>
            <Label for={props.forLabel}>{props.label}</Label>
            <Input value={props.value} onChange={props.onChange} type="select" name={props.name} id={props.name} readOnly={props.readOnly}>
                <option value="">-- {props.default} --</option>
                {selectOption(props.data)}
            </Input>
        </FormGroup>
    );
}

function tooltipElement(value = " *Apabila sampai dengan T + 2 tidak dilakukan penarikan, maka akan otomatis ditransfer pada rekening yang terdaftar{") {
    return (<i className="text-alert float-start">
        {value}
    </i>)
}
function SelectOptionsWithDanger(props) {
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const toggle = () => setTooltipOpen(!tooltipOpen);
    return (
        <FormGroup>
            <Label for={props.forLabel}>{props.label}</Label>
            <Input value={props.value} onChange={props.onChange} type="select" name={props.name} id={props.name} readOnly={props.readOnly}>
                <option value="">-- {props.default} --</option>
                {selectOptionWithTooltip(props.data, tooltipElement)}
            </Input>
            <FormText color="danger" style={{fontSize:'11px'}}>
                {props.info === undefined ? null : (
                    <div>
                        {props.info} <br />
                    </div>
                )}

                {props.error === "" ? null : props.error}
            </FormText>
        </FormGroup>
    );
}

function SelectOptionSearchs(props) {
    if (props.disabled) {
        return <Inputs forLabel={props.forLabel} label={props.label} type={"text"} name={props.name} value={""} readOnly />;
    } else {
        return (
            <FormGroup>
                <Label for={props.forLabel}>{props.label}</Label>
                <Select
                    onChange={props.onChange}
                    options={selectSearch(props.data)}
                    value={props.value}
                // classNamePrefix="vyrill"
                />
            </FormGroup>
        );
    }
}

function SelectEditable(props) {
    // console.log(props.defaultValue, "DEVVss");
    if (props.defaultValue.label) {
        return (
            <FormGroup>
                <Label for={props.forLabel}>{props.label}</Label>
                <Select
                    onChange={props.onChange}
                    options={selectSearch(props.data)}
                    defaultValue={props.defaultValue}
                // classNamePrefix="vyrill"
                />
            </FormGroup>
        );
    } else {
        return (
            <FormGroup>
                {/* <Label for={props.forLabel}>{props.label}</Label> */}
                <Select
                    onChange={props.onChange}
                    options={selectSearch(props.data)}
                // classNamePrefix="vyrill"
                />
            </FormGroup>
        );
    }
}

function InputFotoKTP(props) {
    return (
        <div>
            <div className="text-center pb-3">
                <img src={process.env.PUBLIC_URL + "../../images/kyc-foto/ktp-true.jpg"} className="img-fluid foto-ktp" alt="logo-indofund" />
                <br /> contoh foto
            </div>
            <FormGroup>
                <div className="custom-file">
                    <Label for={props.forLabel}>{props.label}</Label>
                    <CustomInput
                        type="file"
                        onChange={props.onChange}
                        name={props.name}
                        id="exampleFile"
                        label={props.labelInput !== undefined ? props.labelInput : "Maksimal 10MB"}
                        accept="image/*"
                        capture
                    />
                </div>
                <FormText color="danger" className="mt-4">
                    Foto e-KTP harus jelas & terang
                </FormText>
            </FormGroup>
        </div>
    );
}

function InputFotoSelfie(props) {
    if (window.innerWidth <= 800 && typeof InstallTrigger !== "undefined") {
        return (
            <FormGroup>
                <Label htmlFor={props.forLabel}>{props.label}</Label>
                <i>
                    <p>
                        Tidak dapat memunculkan fitur ini, untuk dapat menggunakan fitur dengan baik gunakan peramban <b>Google Chrome atau Safari</b>
                    </p>
                </i>
            </FormGroup>
        );
    } else {
        if (props.fotoDone === false) {
            return (
                <div>
                    <div className="text-center pb-3">
                        <img
                            src={process.env.PUBLIC_URL + "../../images/kyc-foto/fotoselfie-true.jpg"}
                            className="img-fluid foto-selfie"
                            alt="logo-indofund"
                        />
                        <br />
                        contoh foto
                    </div>
                    <FormGroup>
                        <Label htmlFor={props.forLabel}>{props.label} </Label>
                        <InputGroup>
                            <Input invalid placeholder={"Belum " + props.label} readOnly />
                            <InputGroupAddon addonType="prepend">
                                <Button onClick={props.onClick}>Foto selfie</Button>
                            </InputGroupAddon>
                        </InputGroup>
                        <FormText color="danger">
                            Foto selfie harus jelas & terang <b>tanpa</b> memegang foto KTP
                        </FormText>
                    </FormGroup>
                </div>
            );
        } else {
            return (
                <div>
                    <div className="text-center pb-3">
                        <img
                            src={process.env.PUBLIC_URL + "../../images/kyc-foto/fotoselfie-true.jpg"}
                            className="img-fluid foto-selfie"
                            alt="logo-indofund"
                        />
                        <br /> contoh foto
                    </div>
                    <FormGroup>
                        <Label htmlFor={props.forLabel}>{props.label}</Label>
                        <Input valid placeholder="Sudah Foto Selfie " readOnly />
                    </FormGroup>
                </div>
            );
        }
    }
}

function OpenCamera(props) {
    return (
        <Modal isOpen={props.modalselfieWebcam} toggle={props.toggle} className="modal-dialog modal-dialog-centered " size="lg" tabIndex="-1">
            <ModalHeader toggle={props.toggle}>
                <div>
                    <h5>Foto Selfie</h5>
                </div>
            </ModalHeader>
            <ModalBody>
                {props.camera ? (
                    <Camera onTakePhotoAnimationDone={props.handle} isFullscreen={false} />
                ) : (
                    <ImagePreview dataUri={props.dataUri} isFullscreen={false} />
                )}
                <div className="col-md-12 d-flex justify-content-center mt-4">
                    {props.camera === false ? (
                        <div>
                            <Button color="primary" className="mr-4" onClick={props.fotoLagi}>
                                Foto lagi
                            </Button>
                            <Button color="success" onClick={props.successFoto}>
                                Selesai{" "}
                            </Button>
                        </div>
                    ) : null}
                </div>
            </ModalBody>
        </Modal>
    );
}

function FormDomisili(props) {
    return (
        <div className="col-md-6">
            <div className="row">
                <div className="col-12">
                    <InputIcon
                        forLabel={"alamat_domisili"}
                        label={"Alamat Sesuai Domisili"}
                        icon={<Location />}
                        type={"text"}
                        name={"alamat_domisili"}
                        onChange={props.onChangeAlamat}
                        placeholder={"Contoh: Jakarta"}
                        value={props.alamat_domisili}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <SelectOptionSearchs
                        forLabel={"provinsi_domisili"}
                        label={"Provinsi Domisili"}
                        onChange={props.onChangeProvinsi}
                        data={props.listProv}
                        value={props.valueProv}
                    />
                </div>
                <div className="col-md-6">
                    <SelectOptionSearchs
                        forLabel={"kota_domisili"}
                        label={"Kota/Kabupaten Domisili"}
                        onChange={props.onChangeKota}
                        data={props.listKota}
                        value={props.valueKota}
                        disabled={!props.valueProv}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <Inputs
                        forLabel={"kecamatan_domisili"}
                        label={"Kecamatan Domisili"}
                        onChange={props.onChangeKecamatan}
                        type={"text"}
                        name={"kecamatan_domisili"}
                        placeholder={"Contoh: Cengkareng Barat"}
                        error={props.errorKec}
                        value={props.kecamatan_domisili}
                    />
                </div>
                <div className="col-md-6">
                    <Inputs
                        forLabel={"kelurahan_domisili"}
                        label={"Kelurahan Domisili"}
                        onChange={props.onChangeKelurahan}
                        type={"text"}
                        name={"kelurahan_domisili"}
                        placeholder={"Contoh: Cengkareng"}
                        error={props.errorKel}
                        value={props.kelurahan_domisili}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <Inputs
                        forLabel={"kode_pos_domisili"}
                        label={"Kodepos Domisili"}
                        onChange={props.onChangeKodepos}
                        type={"text"}
                        name={"kode_pos_domisili"}
                        placeholder={"Contoh : 12345"}
                        error={props.errKode}
                        maxLength={"5"}
                        value={props.kode_pos_domisili}
                    />
                </div>
            </div>
        </div>
    );
}

function ShowFoto(props) {
    return (
        <FormGroup>
            <Label htmlFor="exampleFile" readOnly>
                {props.label}
            </Label>
            <InputGroup>
                <Input valid placeholder={props.pc} readOnly />
                <InputGroupAddon addonType="prepend">
                    <Button onClick={props.onClick}>Lihat Foto</Button>
                </InputGroupAddon>
            </InputGroup>
        </FormGroup>
    );
}


function ShowDocument(props) {
    return (
        <FormGroup>
            <Label htmlFor="exampleFile" readOnly>
                {props.label}
            </Label>
            <InputGroup>
                <Input valid placeholder={props.pc} readOnly />
                <InputGroupAddon addonType="prepend">
                    <Button onClick={props.onClick}>Lihat Dokumen</Button>
                </InputGroupAddon>
            </InputGroup>
        </FormGroup>
    );
}
function ModalFoto(props) {
    return (
        <Modal isOpen={props.isOpen} toggle={props.toggle} className="modal-dialog modal-dialog-centered " size="lg" tabIndex="-1">
            <ModalHeader toggle={props.toggle}>
                <div>
                    <h5 className="text-center font-weight-bold">{props.title}</h5>
                </div>
            </ModalHeader>
            <ModalBody>
                <img src={props.src} style={{ width: "100%" }} alt="foto-ktp" />
            </ModalBody>
        </Modal>
    );
}

//jika terjadi salah foto tapi data sudah ter input
function CorrectFotoSelfie(props) {
    if (props.data === true) {
        return <InputFotoSelfie forLabel={"foto_selfie"} label={"Foto Selfie"} onClick={props.onChangeInput} fotoDone={props.fotoDone} />;
    } else {
        return <ShowFoto label={"Foto Selfie"} pc={"Sudah Foto Selfie"} onClick={props.onChangeShow} />;
    }
}

//informasi data borrower pesonal
function ShowBPInformasi(props) {
    if (props.data_employee === true) {
        return (
            <div>
                <h5 className="font-weight-bold pb-4 text-primary">Informasi Employee</h5>
                <div className="row">
                    <div className="col-md-6">
                        <Inputs
                            forLabel={"grup_penjamin"}
                            label={"Grup Penjamin"}
                            type={"text"}
                            name={"grup_penjamin"}
                            value={props.grup_penjamin}
                            readOnly={true}
                        />
                    </div>
                    <div className="col-md-6">
                        <Inputs
                            forLabel={"perusahaan_penjamin"}
                            label={"Perusahaan Penjamin"}
                            type={"text"}
                            name={"perusahaan_penjamin"}
                            value={props.perusahaan_penjamin}
                            readOnly={true}
                        />
                    </div>
                    <div className="col-md-12">
                        <Inputs
                            forLabel={"id_sektor"}
                            label={"Sektor Usaha Tempat Bekerja"}
                            type={"text"}
                            name={"id_sektor"}
                            value={props.id_sektor}
                            readOnly={true}
                        />
                    </div>
                </div>
            </div>
        );
    } else if (props.data_micro === true) {
        return (
            <div>
                <h5 className="font-weight-bold pb-4 text-primary">Informasi Usaha</h5>
                <div className="row">
                    <div className="col-md-6">
                        <Inputs
                            forLabel={"lokasi_pasar"}
                            label={"Lokasi Pasar"}
                            type={"text"}
                            name={"lokasi_pasar"}
                            value={props.lokasi_pasar}
                            readOnly={true}
                        />
                    </div>
                    <div className="col-md-6">
                        <Inputs
                            forLabel={"id_sektor"}
                            label={"Sektor Usaha"}
                            type={"text"}
                            name={"id_sektor"}
                            value={props.id_sektor}
                            readOnly={true}
                        />
                    </div>
                    <div className="col-md-12">
                        <FormGroup>
                            <Label for="exampleText">Deskripsi Usaha</Label>
                            <Input type="textarea" value={props.deskripsi_usaha} rows="8" name="deskripsi_usaha" id="exampleText" readOnly />
                        </FormGroup>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div>
                <h5 className="font-weight-bold pb-4 text-primary">Informasi Usaha</h5>
                <div className="row">
                    <div className="col-md-12">
                        <Inputs
                            forLabel={"id_sektor"}
                            label={"Sektor Usaha"}
                            type={"text"}
                            name={"id_sektor"}
                            value={props.id_sektor}
                            readOnly={true}
                        />
                    </div>
                    <div className="col-md-12">
                        <FormGroup>
                            <Label for="exampleText">Deskripsi Usaha</Label>
                            <Input type="textarea" value={props.deskripsi_usaha} rows="8" name="deskripsi_usaha" id="exampleText" readOnly />
                        </FormGroup>
                    </div>
                </div>
            </div>
        );
    }
}

function ShowBPKontakDarurat(props) {
    if (props.data_employee !== true) {
        return (
            <div>
                <hr />
                <h5 className="font-weight-bold pb-4 text-primary">Kontak Darurat (Keluarga Yang Tidak Satu Rumah)</h5>
                <div className="row mt-5">
                    <div className="col-md-6">
                        <Inputs
                            forLabel={"nama_kontak_darurat"}
                            label={"Nama Lengkap"}
                            type={"text"}
                            name={"nama_kontak_darurat"}
                            value={props.nama}
                            readOnly={true}
                        />
                    </div>
                    <div className="col-md-6">
                        <Inputs
                            forLabel={"no_handphone_kontak_darurat"}
                            label={"Nomor Handphone"}
                            type={"text"}
                            name={"no_handphone_kontak_darurat"}
                            value={props.nohp}
                            readOnly={true}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <Inputs
                            forLabel={"alamat_kontak_darurat"}
                            label={"Alamat Kontak"}
                            type={"text"}
                            name={"alamat_kontak_darurat"}
                            value={props.alamat}
                            readOnly={true}
                        />
                    </div>
                    <div className="col-md-6">
                        <Inputs
                            forLabel={"hubungan_kontak_darurat"}
                            label={"Hubungan dengan kontak"}
                            type={"text"}
                            name={"hubungan_kontak_darurat"}
                            value={props.hubungan}
                            readOnly={true}
                        />
                    </div>
                </div>
            </div>
        );
    } else {
        return null;
    }
}
//end info data borrower personal

//if else jika lender belum isi form
function FormLender(props) {
    if (props.updateLender === true) {
        return <Inputs forLabel={props.forLabel} label={props.label} type={"text"} name={props.name} value={props.value} readOnly />;
    } else {
        return (
            <SelectOptions
                forLabel={props.forLabel}
                label={props.label}
                onChange={props.onChange}
                name={props.name}
                default={props.default}
                data={props.data}
            />
        );
    }
}

function FormSearch(props) {
    if (props.updateLender === true || props.isDisabled) {
        return <Inputs forLabel={props.forLabel} label={props.label} type={"text"} name={props.name} value={props.value} readOnly />;
    } else {
        return <SelectOptionSearchs forLabel={props.forLabel} label={props.label} onChange={props.onChange} data={props.data} />;
    }
}
// end if else

function IconVerifLender(props) {
    return (
        <div>
            <a className="ml-2 d-inline-flex" tabIndex="0" id="Popover1" onMouseEnter={props.onMouseEnter} onMouseLeave={props.onMouseLeave}>
                {props.icon}
                {/* <i className="material-icons text-success" style={{paddingTop: '10px', paddingLeft: '5px'}}>check_circle</i>  */}
            </a>
            <Popover placement="top" isOpen={props.isOpen} target="Popover1" toggle={props.toggle}>
                <PopoverHeader className="popover-header"></PopoverHeader>
                <PopoverBody>
                    <p>{props.info}</p>
                </PopoverBody>
            </Popover>
        </div>
    );
}

export {
    Inputs,
    InputIcon,
    SelectOptions,
    SelectOptionsWithDanger,
    SelectOptionSearchs,
    InputFotoKTP,
    InputFotoSelfie,
    FormDomisili,
    ShowFoto,
    ModalFoto,
    CorrectFotoSelfie,
    FormLender,
    FormSearch,
    IconVerifLender,
    OpenCamera,
    InputNominal,
    InputTextArea,
    UploadDoc,
    ShowBPInformasi,
    ShowBPKontakDarurat,
    SelectEditable,
    ShowDocument,
};
