import React, { Component } from "react";
import FormTicketing from "./FormTicketing";
// import { getTopikTiketing } from "../../../redux/action/Tiketing";
import { connect } from "react-redux";
// import { AllProject } from "../../../redux/action/Project";
const queryString = require("query-string");

class Layanan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 1,
      list_tab: [
        "Layanan Pengaduan Pengguna",
        "Jenis Layanan",
        "Bentuk Pengaduan",
        "Penyampaian Pengaduan dan Permintaan Informasi",
        "Standar Waktu Penyelesaian",
        "Penyelesaian Pengaduan",
        "Whistleblowing System"

      ]
    };
  }
  async componentDidMount() {
    const values = queryString.parse(this.props.location.search);
    if (this.props.location.search) {
      this.setState({
        activeTab: +values.state
      });
    }
  }
  render() {
    const background = {
      backgroundImage: "url(/images/banner/BannerPusatBantuan.png)"
    };
    const { list_tab, activeTab } = this.state;
    return (
      <div>
        <section id="faq" className="with-pad">
          <div className="container">
            <div className="header-image mb-5" style={background}>
              <h2 className="title-head">Layanan Pengaduan Penggunaan</h2>
            </div>
            <div className="row">
              <div className="col-12 col-lg-3">
                <div className="tabs">
                  {list_tab.map((text, i) => {
                    return (
                      <p
                        key={i}
                        className={`${activeTab === i + 1 ? "active" : ""}`}
                        onClick={() => this.setState({ activeTab: i + 1 })}>
                        {" "}
                        {text}{" "}
                      </p>
                    );
                  })}
                </div>
              </div>
              <div className="col-12 col-lg-9">
                {activeTab === 1 && (
                  <div id="layanan" className="questions">
                    <h3>Layanan Pengaduan Pengguna</h3>
                    <div className="question">
                      <p className="text">
                        Layanan Pengaduan Indofund.id dibentuk dan dikelola sesuai dengan arahan dan ketentuan dalam Peraturan Otoritas Jasa Keuangan Republik Indonesia Nomor 22 Tahun 2023 Tentang Perlindungan Konsumen Dan Masyarakat Di Sektor Jasa Keuangan
                        <br />
                        <br />
                        Dengan diberlakukannya layanan ini sesuai regulasi, Indofund.id berkomitmen untuk memberikan bantuan
                        yang prima dalam rangka mengelola hubungan serta menyajikan informasi secara jelas dan terbuka kepada
                        Anda, pengguna setia kami. Pengaduan Anda akan ditampung, dikoordinasikan, dan diselesaikan oleh
                        Petugas Customer Service (CS) Indofund.id .
                        <br />
                        <br />
                        Sebelum melayangkan pertanyaan, memberikan saran atau kritik, maupun mengajukan keluhan, kami sangat
                        menyarankan Anda untuk mempelajari terlebih dahulu rincian Layanan Pengaduan Indofund.id berikut ini;
                      </p>
                    </div>
                  </div>
                )}
                {activeTab === 2 && (
                  <div id="jenis-layanan" className="questions">
                    <h3>Jenis Layanan</h3>
                    <div className="question">
                      <b>
                        <p>Formulir Ticketing</p>
                      </b>
                      <p className="text mb-5">
                        Pengguna dan Pelapor dapat menyampaikan pengaduan dengan mengisi formulir yang tersedia di situs web
                        Indofund, Anda harus memiliki Akun Indofund / Login terlebih dahulu.
                      </p>

                      <b>
                        <p>Email</p>
                      </b>
                      <p className="text mb-5">
                        Kirimkan pengaduan Anda ke cs@indofund.id. Tim kami akan segera merespons sesuai waktu operasional
                        Indofund.id.
                      </p>

                      <b>
                        <p>Telepon</p>
                      </b>
                      <p className="text">
                        Anda bisa langsung berkomunikasi dengan Tim CS Indofund.id melalui telepon (021) 25982507 pada hari
                        Senin - Jumat pukul 08.00 - 17.00 WIB mengikuti waktu operasional Indofund.id.
                      </p>
                    </div>
                  </div>
                )}
                {activeTab === 3 && <BentukPengaduan />}
                {activeTab === 4 && (
                  <div id="penyampaian" className="questions">
                    <h3>Penyampaian Pengaduan dan Permintaan Informasi</h3>
                    <div className="question">
                      <p className="text">
                        Indofund.id menyediakan layanan bagi para pengguna untuk menyampaikan pengaduan ataupun meminta
                        informasi mengenai produk Perusahaan melalui layanan Customer Service dengan ruang lingkup:
                      </p>
                      <ol className="text">
                        <li>
                          <p className="text">
                            Melayani seluruh pertanyaan, keluhan, masukan, saran dan kritik untuk membantu Indofund.id
                            menjadi lebih baik dalam melayani penggunannya.
                          </p>
                        </li>
                        <li>
                          <p className="text">
                            Memberikan solusi kepada pengguna sehubungan dengan permasalahan yang disampaikan.
                          </p>
                        </li>
                        <li>
                          <p className="text">
                            Melakukan klarifikasi kembali atas permintaan informasi dan/atau keluhan kepada Pengguna untuk
                            memastikan bahwa tidak ada kesalahpahaman dan kedua belah pihak mengenai permintaan informasi
                            dan/atau keluhan Pengguna yang disampaikan.
                          </p>
                        </li>
                      </ol>
                    </div>
                  </div>
                )}
                {activeTab === 5 && (
                  <div id="standar" className="questions">
                    <h3>Standar Waktu Penyelesaian</h3>
                    <div className="question">
                      <p className="text">Berdasarkan sifat dan cara penanganannya, jenis pengaduan dibagi menjadi 5 yaitu:</p>
                      <ol className="text">
                        <li>
                          <p className="text">
                            Untuk pemberian informasi atas pertanyaan yang diajukan pengguna, standar waktu penyelesaian
                            maksimal 5 (lima) hari kerja sejak pengaduan diterima.
                          </p>
                        </li>
                        <li>
                          <p className="text">
                            Untuk pemberian respons dan penanganan atas masalah yang dihadapi pengguna, standar waktu
                            penyelesaiannya adalah maksimal 10 (sepuluh) hari kerja sejak pengaduan diterima.
                          </p>
                        </li>
                        <li>
                          <p className="text">
                            Untuk pemberian respons dan penanganan atas masalah yang membutuhkan penyelesaian dengan
                            melibatkan Lembaga pihak ketiga, maka akan diselesaikan berdasarkan kesepakatan secara tatap
                            muka dengan pengguna dalam rangka mengupayakan penyelesaian yang sebaik - baiknya.
                          </p>
                        </li>
                        <li>
                          <p className="text">
                            Dalam hal terdapat kondisi tertentu, Indofund dapat memperpanjang jangka waktu paling lama 10 (sepuluh) hari kerja. Adapun yang dimaksud dengan kondisi tertentu merupakan{" "}
                          </p>
                        </li>
                        <ul className="mb-2">
                          <li>
                            Pengaduan yang disampaikan oleh Konsumen dan/atau Perwakilan Konsumen memerlukan penelitian
                            khusus terhadap dokumen Indofund; dan/atau
                          </li>
                          <li>terdapat hal-hal lain yang berada di luar kendali Indofund.</li>
                        </ul>
                        <li>
                          <p className="text">
                            Perpanjangan jangka waktu penyelesaian Pengaduan wajib diberitahukan secara tertulis secara
                            kepada Konsumen dan/atau Perwakilan Konsumen yang mengajukan Pengaduan sebelum jangka waktu
                            berakhir.
                          </p>
                        </li>
                      </ol>
                    </div>
                  </div>
                )}

                {activeTab === 6 && (
                  <div id="standar" className="questions">
                    <h3>Penyelesaian Pengaduan</h3>
                    <div className="question">
                      <ol className="text">
                        <li>
                          <p className="text">
                            Pertanyaan, keluhan, saran dan kritik akan ditanggapi di waktu operasional Indofund.id yakni
                            setiap hari Senin - Jumat pukul 08:00 sampai 17:00, kecuali pada hari libur.
                          </p>
                        </li>
                        <li>
                          <p className="text">
                            Seluruh pertanyaan, keluhan yang disampaikan akan dicatat dan didokumentasikan pada Laporan
                            Pengaduan Konsumen.
                          </p>
                        </li>
                        <li>
                          <p className="text">
                            Untuk user yang mengajukan pertanyaan, akan diarahkan ke bagian FAQ yang berada di website
                            terlebih dahulu. Bilamana pertanyaan yang diajukan oleh lender tidak terdapat di FAQ, customer
                            service akan menanggapi pertanyaan tersebut. Jika customer service tidak dapat menjawabnya
                            maka pertanyaan akan diteruskan kepada bagian terkait/ manajemen.
                          </p>
                        </li>
                        <li>
                          <p className="text">
                            Untuk user yang mengajukan saran dan kritik, saran dan kritik akan didokumentasikan oleh
                            customer service dan diteruskan kepada manajemen untuk ditindak lanjuti.
                          </p>
                        </li>

                        <li>
                          <p className="text">
                            Customer service akan melakukan klarifikasi kembali atas permintaan informasi dan/atau keluhan
                            kepada Pengguna untuk memastikan pokok permasalahan yang dikeluhkan atau diminta penjelasan
                            oleh pengguna.
                          </p>
                        </li>

                        <li>
                          <p className="text">
                            Setelah respon diberikan, CS harus memastikan apakah respon tersebut telah menyelesaikan
                            masalah yang dikeluhkan oleh pengguna atau pengguna telah mendapatkan informasi yang diminta
                          </p>
                        </li>

                        <li>
                          <p className="text">
                            Setelah CS mendapatkan feedback dari pengguna, bahwa laporan atas permasalahan tersebut telah
                            terselesaikan, maka CS akan mengubah status pada Laporan Pengaduan Konsumen
                          </p>
                        </li>
                      </ol>
                    </div>
                  </div>
                )}

                {activeTab === 7 && (
                  <div id="standar" className="questions">
                    <h3>Whistleblowing System</h3>
                    <div className="question">
                      <p className="text">
                        Dalam rangka menjaga serta meningkatkan reputasi Indofund.id , diperlukan sarana dan sistem pengendalian risiko melalui partisipasi active dari seluruh pihak yang berkepentingan dan stakeholders untuk menyampaikan laporan pengaduan pelanggaran disiplin yaitu perbuatan atau indikasi Fraud dan Non-Fraud yang dapat merugikan pengguna maupun Indofund.id .
                        <br />
                        <br />
                        Hal ini sejalan dengan Pilar ke 2 Strategi Anti Fraud (SAF) yaitu Pilar Deteksi yang merupakan bagian dari sistem pengendalian Fraud yang salah satu diantaranya mencakup mekanisme Whistleblowing System (WBS).
                        <br />
                        <br />
                        Sebelum melayangkan pertanyaan, memberikan saran atau kritik, maupun mengajukan keluhan, kami sangat
                        menyarankan Anda untuk mempelajari terlebih dahulu rincian Layanan Pengaduan Indofund.id berikut ini;
                        <br />
                        <br />
                        Pelapor dapat menggunakan sarana yang telah disediakan yaitu :
                        <br />
                        <br />
                        <b>Email : legal@indofund.id</b>
                        <br />
                        <br />
                      </p>
                      <b><p>Kerahasiaan Pelapor:</p></b>
                      <p className="text">
                        Sebagai wujud komitmen Indofund.id  untuk menjaga kerahasiaan data pelaporan dari pihak-pihak yang tidak berkepentingan maka Indofund.id  akan memberikan:
                        <br />
                        <br />
                        <ul>
                          <li>Jaminan atas kerahasiaan identitas pelapor</li>
                          <li>Jaminan atas kerahasiaan isi laporan yang disampaikan oleh pelapor</li>
                        </ul>
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
        {localStorage.jwtToken ? <FormTicketing {...this.props} /> : null}
      </div>
    );
  }
}

const listBentukPengaduan = [
  {
    text: "Kesulitan Mendaftar sebagai Pengguna (Register)",
    description: "Pengaduan ini terjadi ketika pengunjung situs Akseleran kesulitan saat akan mendaftar sebagai Pengguna layanan Indofund."
  },
  {
    text: "Kesulitan Masuk ke Akun Indofund (Login)",
    description: "Pengaduan ini terjadi ketika Pengguna layanan Indofund yang sudah terdaftar mengalami kesulitan saat akan masuk ke akunnya."
  },
  {
    text: "Keterlambatan Pembayaran Pinjaman",
    description: "Pengaduan ini terjadi ketika peminjam dana terlambat melakukan pembayaran di luar jadwal yang sudah ditetapkan sebelumnya."
  },
  {
    text: "Kesulitan Dalam Pendanaan Proyek",
    description:
      "Indofund menyediakan nomor telepon khusus yang dapat digunakan oleh Pengguna dan Pelapor untuk menyampaikan pengaduan. Nomor telepon ini dapat ditemukan di situs web Indofund. Waktu resmi untuk penyampaian pengaduan melalui jalur telepon adalah jam 08.00 - 17.00 WIB di hari kerja."
  },
  {
    text: "Kendala Pencairan Dana (Withdraw)",
    description:
      "Pengaduan ini terjadi ketika Pengguna mengalami kesulitan saat akan melakukan penarikan dana ke rekening bank yang telah didaftarkan."
  },
  {
    text: "Tidak dapat Mengakses Situs Indofund",
    description:
      "Pengaduan ini terjadi apabila Pengguna tidak dapat mengakses atau membuka situs Indofund sehingga tidak dapat menggunakan layanan Indofund."
  },
  {
    text: "Kesulitan dalam Mengajukan Pinjaman",
    description:
      "Pengaduan ini terjadi ketika Pengguna mengalami kesulitan saat akan mengajukan pinjaman. Bisa jadi karena lambatnya respon dari tim Indofund atau ketidakpahaman mengenai cara mengajukan pinjaman."
  },
  {
    text: "Adanya Kesalahan Informasi / Data yang Disampaikan Indofund kepada Pengguna",
    description:
      "Pengaduan ini terjadi ketika ada kesalahan informasi/data yang disampaikan oleh Indofund dalam situs website maupun aplikasi yang digunakan oleh Pengguna."
  },
  {
    text: "Pengaduan Lain yang Terkait Langsung dengan Layanan Indofund",
    description:
      "Pengaduan ini terjadi ketika Pengguna merasa dirugikan/mengalami kesulitan ketika menggunakan layanan Indofund lainnya yang belum tercantum di dalam poin yang disampaikan di atas."
  },
  {
    text: "Pengaduan Masalah Top Up Saldo",
    description:
      "Pengaduan ini terjadi ketika Pengguna telah melakukan transaksi Top Up pada E-wallet Indofund, namun saldo pengguna tidak bertambah."
  },
  {
    text: "Tanda Tangan Digital",
    description:
      "Pengaduan ini terjadi ketika Pengguna ingin melakukan pendaftaran ke Digisign karena Expired atau melakukan tanda tangan langsung pada Digisign sehingga statusnya tidak berubah pada akun Indofund."
  }
];

const BentukPengaduan = () => {
  return (
    <div id="standar" className="questions">
      <h3>Bentuk Pengaduan</h3>
      <div className="question">
        <p className="text">Berdasarkan sifat dan cara penanganannya, jenis pengaduan dibagi menjadi 11 yaitu:</p>
        <ol>
          {listBentukPengaduan.map((el, index) => {
            return (
              <li key={index}>
                <b>
                  <p className="my-3">{el.text}</p>
                </b>
                <p className="text">{el.description}</p>
              </li>
            );
          })}
        </ol>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    tiketing: state.tiketing
  };
};
export default connect(mapStateToProps)(Layanan);
