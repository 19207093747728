import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { Link } from "react-router-dom";
import "./PengkinianData.scss";
import ContentModalEntity from "./entity/ContentModal";
import ContentModalPersonal from "./personal/ContentModal";

function PengkinianData(props) {
    return (
        <Modal isOpen={props.isOpen} toggle={props.toggle} className="modal-dialog modal-dialog-centered modal-size" tabIndex="-1">
            <ModalHeader toggle={props.toggle} className="px-5 pt-5 pb-0">
                <div className="text-left flex-fill">
                    <h5 className="modal-title font-weight-bold mb-4">Pengkinian Data</h5>
                    <hr className="m-0"/>
                </div>

            </ModalHeader>
            <ModalBody className="px-5 pb-5 pt-4" style={{ overflowX: 'hidden' }}>
                <p className="mb-5 f-14">
                    Untuk melanjutkan pendanaan, mohon untuk mengisi informasi pengkinian data Anda
                </p>
                {props.jenis_pengguna === 1 ?
                    <ContentModalPersonal toggle={props.toggle} loading={props.loading} onSubmit={props.onSubmit} master={props.master} />
                    :
                    <ContentModalEntity toggle={props.toggle} loading={props.loading} onSubmit={props.onSubmit} master={props.master} lender={props.lender}/>
                }
            </ModalBody>
        </Modal>
    );
}
export default PengkinianData;
