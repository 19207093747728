import React, { Component } from "react";
import NoData from "../../assets/no data/NoData";
import Pagination from "react-js-pagination";
import { percentageConvert, sukuBunga } from "../../../library/helper/helper";
import { LendApp } from "../../../redux/action/Lender";
import { connect } from "react-redux";
import { LoadingPortofolio } from "../../assets/loading data/LoadingLender";
import PortfolioListLayout from "./PortfolioListLayout";
import moment from "moment";

class CMenggalangDana extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {

            },
            query: {
                page: 1,
                limit: 15,
                status: "Pending",
                search: "",
                totalData: 0,
                totalPage: 0,
            },
        };
        this.debounceTimeout=null;
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(pageNumber) {
        window.scrollTo(0, 0);
        this.setState(
            (prevState) => ({
                query: { ...prevState.query, page: pageNumber }, // Update the page number in the query
            }),
            () => {
                this.fetchData(pageNumber); // Fetch data after state update
            }
        );
    }

    handleChangeSearch = (event) => {
        this.setState({ query: { ...this.state.query, search: event.target.value } }, () => {
            clearTimeout(this.debounceTimeout);
            this.debounceTimeout = setTimeout(() => {
                this.fetchData(1);
            }, 500);
        });
    };

    componentDidMount = async () => {
        window.scrollTo(0, 0);
        this.fetchData(this.state.page);
    };
    fetchData = async (page) => {
        await this.props.dispatch(LendApp( { ...this.state.query, page }));
        // console.log(this.props.lender.lenderLendPending)
        this.setState({
            data: this.props.lender.lenderLendPending
        });
    }

    MenggalangDana = () => {
        if (this.props.data === undefined) {
            return <div></div>;
        } else {

            const currentTodos = this.props.lender.lenderLendPending
            // .filter((item) =>
            //     this.state.filter === ""
            //         ? (item.id_pinjaman && item.id_pinjaman.status_proyek === "Menggalang dana") ||
            //         (item.id_pinjaman && item.id_pinjaman.status_proyek === "Proyek sedang berjalan")
            //         : item.id_pinjaman.nama_pinjaman.toLowerCase().includes(this.state.filter.toLowerCase()) &&
            //         ((item.id_pinjaman && item.id_pinjaman.status_proyek === "Menggalang dana") || "Proyek sedang berjalan")
            // )
            // .sort(compare)
            // .slice(indexOfFirstTodo, indexOfLastTodo);

            if (currentTodos.length > 0) {
                const hasil = currentTodos.map((val) => (
                    <div key={val._id}>
                        <div className="transaction p-3">
                            <div className="card border-0 shadow-sm">
                                <div className="card-body">
                                    <div className="row align-items-center">
                                        <div className="col-12 col-xl-12 mb-3 mb-xl-0">
                                            <div className="row">
                                                <div className="col-6 col-md-2 mb-3">
                                                    <p className="fs-n1 text-secondary mb-1">Nama Proyek</p>
                                                    <p className="mb-0" style={{ display: "flex" }}>
                                                        {val.loanName}
                                                    </p>
                                                </div>
                                                <div className="col-6 col-md-1 mb-3">
                                                    <p className="fs-n1 text-secondary mb-1">Bunga</p>
                                                    <p className="mb-0">
                                                        {/* {sukuBunga(
                                                            val.id_pinjaman.tipe_pinjaman,
                                                            val.id_pinjaman.jenis_pinjaman,
                                                            val.id_pinjaman.suku_bunga_pinjaman,
                                                            val.id_pinjaman.suku_bunga_konsumtif,
                                                            val.id_pinjaman.suku_bunga_micro,
                                                            val.id_pinjaman.spread_bunga,
                                                            true
                                                        )} */}
                                                        {percentageConvert(val.loanInterst)}
                                                    </p>
                                                </div>
                                                <div className="col-6 col-md-3 mb-3">
                                                    <p className="fs-n1 text-secondary mb-1">Nominal Pendanaan</p>
                                                    <p className="mb-0">Rp {val.lendingAmount.toLocaleString("IN")}</p>
                                                </div>
                                                <div className="col-6 col-md-3 mb-3 mb-xl-0">
                                                    <p className="fs-n1 text-secondary mb-1">Status Proyek</p>
                                                    <p className="mb-0">{val.currentLoanStatus}</p>
                                                </div>
                                                <div className="col-6 col-md-3 mb-3 mb-xl-0">
                                                    <p className="fs-n1 text-secondary mb-1">Tanggal Mulai Proyek</p>
                                                    <p className="mb-0">{moment(new Date(val.lendingDate)).locale("id").format("LL")}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ));
                return hasil;
            } else {
                return (
                    <div className="transactions-wrapper m-n3">
                        <NoData symbol={<i className="material-icons-outlined">assignment</i>} keterangan={"Belum ada Transaksi"} />
                        <div className="text-center">
                            <a href="/proyek" className="btn btn-outline-primary btn-mulai-dana">
                                Mulai Danai Sekarang
                            </a>
                        </div>
                    </div>
                );
            }
        }
    };

    render() {
        return (
            // <>
            //     {this.props.lender.isLoadingLendApp ?
            //         <div>
            //             {Array.from({ length: 10 }).map((_, index) => (
            //                 <LoadingPortofolio key={index} />
            //             ))}
            //         </div>
            //         :
            //         <div>
            //             <div className="d-flex align-items-center justify-content-between my-3">
            //                 <div>
            //                     <form>
            //                         <div className="input-group">
            //                             <input
            //                                 className="form-control"
            //                                 type="text"
            //                                 id="keyword"
            //                                 name="keyword"
            //                                 placeholder="Cari Transaksi"
            //                                 value={filter}
            //                                 onChange={this.handleChangeSearch}
            //                             />
            //                             <div className="input-group-append">
            //                                 <div className="input-group-text">
            //                                     <i className="material-icons-outlined">search</i>
            //                                 </div>
            //                             </div>
            //                         </div>
            //                     </form>
            //                 </div>
            //             </div>
            //             {this.MenggalangDana()}

            //             <div className="d-flex justify-content-center mt-3">
            //                 <Pagination
            //                     hideDisabled
            //                     itemClass="page-item"
            //                     linkClass="page-link"
            //                     activePage={this.props.lender.lenderLendPendingPagination.page}
            //                     itemsCountPerPage={this.props.lender.lenderLendPendingPagination.limit}
            //                     totalItemsCount={this.props.lender.lenderLendPendingPagination.totalData}
            //                     onChange={this.handleClick.bind(this)}
            //                 />
            //             </div>
            //         </div>
            //     }
            // </>
            <PortfolioListLayout filter={this.state.query.search} isLoading={this.props.lender.isLoadingLendAppPending} limit={this.props.lender.lenderLendPendingPagination.limit} page={this.props.lender.lenderLendPendingPagination.page} onChangePage={this.handleClick.bind(this)} renderComponent={this.MenggalangDana} totalData={this.props.lender.lenderLendPendingPagination.totalData} handleChangeSearch={this.handleChangeSearch} />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        lender: state.lender
    };
};

export default connect(mapStateToProps)(CMenggalangDana);
