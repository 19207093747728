import React, { Component } from "react";
import "./Registred.scss";

class Registred extends Component {
    render() {
        return (
            <div>
                <section id="registered-in" className="welcome pt-5 pb-5" style={{ paddingTop: 60 + "px !important" }}>
                    <div className="container">
                        <div className="d-flex flex-wrap justify-content-center">
                            <div className="icon mr-md-5 d-flex align-items-center flex-column mb-4">
                                <img className="ojk img-fluid mb-4" src={process.env.PUBLIC_URL + "images/partners-icon/ojk.svg"} alt="logo-ojk" />
                                <p className="mb-0">Berizin dan diawasi oleh OJK</p>
                                <a
                                    href="https://www.ojk.go.id/id/kanal/iknb/financial-technology/Documents/Penyelenggara%20Fintech%20Lending%20Berizin%20per%205%20Januari%202023.pdf"
                                    target="_blank"
                                    className="d-flex text-primary font-weight-semi-bold"
                                    rel="noopener noreferrer">
                                    Lihat no. tanda daftar <i className="material-icons">chevron_right</i>
                                </a>
                            </div>
                            <div className="icon mr-md-5 d-flex align-items-center flex-column mb-4">
                                <img className="afpi img-fluid mb-4" src={process.env.PUBLIC_URL + "images/partners-icon/afpi.png"} alt="logo-afpi" />
                                <div className="ml-4">
                                    <p className="mb-0">Terdaftar sebagai anggota AFPI</p>
                                    <a
                                        href="https://www.afpi.or.id/profilemember/Indofund.id"
                                        target="_blank"
                                        className="d-flex text-primary font-weight-semi-bold"
                                        rel="noopener noreferrer">
                                        Baca selengkapnya <i className="material-icons">chevron_right</i>
                                    </a>
                                </div>
                            </div>
                            <div className="icon mr-md-5 d-flex align-items-center flex-column mb-4">
                                <img className="afpi img-fluid mb-4" src={process.env.PUBLIC_URL + "images/partners-icon/iso.jpg"} alt="logo-afpi" />
                                <div className="ml-4">
                                    <p className="mb-0">Telah tersertifikasi</p>
                                    <a
                                        href="https://m.certipedia.com/?id=9000010306"
                                        target="_blank"
                                        className="d-flex text-primary font-weight-semi-bold"
                                        rel="noopener noreferrer">
                                        Baca selengkapnya <i className="material-icons">chevron_right</i>
                                    </a>
                                </div>
                            </div>
                            <div className="icon mr-md-5 d-flex align-items-center flex-column mb-4">
                                <img className="afpi img-fluid mb-4" src={process.env.PUBLIC_URL + "images/partners-icon/logo-laps-sjk.png"} alt="logo-afpi" />
                                <div className="">
                                    <p className="mb-0">Terdaftar sebagai anggota LAPS SJK</p>
                                    <a
                                        href="https://lapssjk.id/daftar-anggota/"
                                        target="_blank"
                                        className="d-flex text-primary font-weight-semi-bold"
                                        rel="noopener noreferrer">
                                        Baca selengkapnya <i className="material-icons">chevron_right</i>
                                    </a>
                                </div>
                            </div>
                            <div className="icon mr-md-5 d-flex align-items-center flex-column mb-4">
                                <img
                                    className="afpi img-fluid mb-4"
                                    src={process.env.PUBLIC_URL + "images/partners-icon/kominfo.png"}
                                    alt="logo-afpi"
                                />
                                <div className="ml-4">
                                    <p className="mb-0">Terdaftar PSE di Kominfo</p>
                                    <a
                                        href="https://pse.kominfo.go.id/tdpse-detail/5128"
                                        target="_blank"
                                        className="d-flex text-primary font-weight-semi-bold"
                                        rel="noopener noreferrer">
                                        Baca selengkapnya <i className="material-icons">chevron_right</i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}
export default Registred;
